import { Outlet } from 'react-router-dom';
import ContentContainer from '../base/ContentContainer';
import Sidebar from './Sidebar';

function GettingStarted() {
    return (
        <div className="h-full w-full flex">
            <Sidebar />
            <div className="h-full w-full flex flex-col grow items-stretch justify-start overflow-x-hidden">
                <ContentContainer>
                    <Outlet />
                </ContentContainer>
            </div>
        </div>
    );
}

export default GettingStarted;

import { Link } from 'react-router-dom';
import { ArrowRight, Box, Cpu, Database, Play } from 'react-feather';

function HomeIndex() {
    const linkStyles =
        'text-blue-500 hover:text-blue-600 hover:underline dark:text-primary-dark-text-accent dark:hover:text-primary-dark-text-accent';

    return (
        <div className="space-y-6">
            <div className="space-y-2 pb-2">
                <h1 className="font-bold text-3xl pb-2">🦜 Welcome to Coop!</h1>
                <p>
                    Coop is our platform for developing and sharing AI-powered
                    research.
                </p>
                <p>
                    Questions or suggestions? Please post a message at our{' '}
                    <a href="https://discord.com/invite/mxAYkjfy9m">Discord</a>{' '}
                    or email us at{' '}
                    <a
                        href="mailto:info@expectedparrot.com"
                        className={linkStyles}
                    >
                        info@expectedparrot.com
                    </a>
                </p>
            </div>
            <div className="space-y-1">
                <h1 className="font-bold text-2xl pb-1">
                    Start with a tutorial
                </h1>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6 pt-2">
                    <Link
                        to="/getting-started/edsl-installation"
                        className="group block p-6 bg-gray-50 dark:bg-gray-800 rounded-lg"
                    >
                        <div className="flex items-start">
                            <div className="flex-shrink-0">
                                <div className="p-2 bg-blue-100 dark:bg-blue-900 rounded">
                                    <Play className="w-6 h-6 text-blue-600 dark:text-blue-200" />
                                </div>
                            </div>
                            <div className="ml-4">
                                <h2 className="flex items-center gap-1 text-lg font-semibold text-gray-900 dark:text-gray-100">
                                    Getting Started
                                    <ArrowRight className="w-4 h-4 opacity-0 group-hover:opacity-100 group-hover:translate-x-1 transition-all motion-reduce:transition-none duration-150" />
                                </h2>
                                <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">
                                    Install EDSL, our open-source Python package
                                </p>
                            </div>
                        </div>
                    </Link>
                    <Link
                        to="/getting-started/coop-how-it-works"
                        className="group block p-6 bg-gray-50 dark:bg-gray-800 rounded-lg"
                    >
                        <div className="flex items-start">
                            <div className="flex-shrink-0">
                                <div className="p-2 bg-blue-100 dark:bg-blue-900 rounded">
                                    <Box className="w-6 h-6 text-blue-600 dark:text-blue-200" />
                                </div>
                            </div>
                            <div className="ml-4">
                                <h2 className="flex items-center gap-1 text-lg font-semibold text-gray-900 dark:text-gray-100">
                                    Content Management
                                    <ArrowRight className="w-4 h-4 opacity-0 group-hover:opacity-100 group-hover:translate-x-1 transition-all motion-reduce:transition-none duration-150" />
                                </h2>
                                <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">
                                    Share your research with other users
                                </p>
                            </div>
                        </div>
                    </Link>
                    <Link
                        to="/getting-started/coop-remote-cache"
                        className="group block p-6 bg-gray-50 dark:bg-gray-800 rounded-lg"
                    >
                        <div className="flex items-start">
                            <div className="flex-shrink-0">
                                <div className="p-2 bg-blue-100 dark:bg-blue-900 rounded">
                                    <Database className="w-6 h-6 text-blue-600 dark:text-blue-200" />
                                </div>
                            </div>
                            <div className="ml-4">
                                <h2 className="flex items-center gap-1 text-lg font-semibold text-gray-900 dark:text-gray-100">
                                    Remote Cache
                                    <ArrowRight className="w-4 h-4 opacity-0 group-hover:opacity-100 group-hover:translate-x-1 transition-all motion-reduce:transition-none duration-150" />
                                </h2>
                                <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">
                                    Store your LLM calls in our database
                                </p>
                            </div>
                        </div>
                    </Link>

                    <Link
                        to="/getting-started/coop-remote-inference"
                        className="group block p-6 bg-gray-50 dark:bg-gray-800 rounded-lg"
                    >
                        <div className="flex items-start">
                            <div className="flex-shrink-0">
                                <div className="p-2 bg-blue-100 dark:bg-blue-900 rounded">
                                    <Cpu className="w-6 h-6 text-blue-600 dark:text-blue-200" />
                                </div>
                            </div>
                            <div className="ml-4">
                                <h2 className="flex items-center gap-1 text-lg font-semibold text-gray-900 dark:text-gray-100">
                                    Remote Inference
                                    <ArrowRight className="w-4 h-4 opacity-0 group-hover:opacity-100 group-hover:translate-x-1 transition-all motion-reduce:transition-none duration-150" />
                                </h2>
                                <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">
                                    Run large jobs on our server (we've given
                                    you 100 free credits to start!)
                                </p>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default HomeIndex;

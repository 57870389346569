interface KeyValueTableProps {
    title: string;
    keyColName: string;
    valueColName: string;
    data: {
        id: number;
        key: string;
        value: any;
    }[];
}

function KeyValueTable({
    title,
    keyColName,
    valueColName,
    data,
}: KeyValueTableProps) {
    return (
        <div className="relative overflow-x-auto sm:rounded-lg">
            <table className="table-fixed w-full text-md text-left rtl:text-right dark:text-primary-dark-text">
                <caption className="p-5 text-lg font-semibold text-left rtl:text-right text-gray-900 dark:text-primary-dark-text bg-white dark:bg-primary-dark-bg">
                    {title}
                </caption>
                <thead className="text-s text-gray-700 dark:text-primary-dark-text bg-sky-100 dark:bg-sky-700/50">
                    <tr>
                        <th className="w-1/4 px-6 py-3">{keyColName}</th>
                        <th className="w-3/4 px-6 py-3">{valueColName}</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((record) => (
                        <tr
                            key={record.id}
                            className="bg-white dark:bg-primary-dark-bg hover:bg-gray-50 dark:hover:bg-gray-50/5 border-b dark:border-gray-100/20"
                        >
                            <td className="break-words px-6 py-4">
                                {record.key}
                            </td>
                            <td className="px-6 py-4">
                                {record.value && record.value.toString()}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default KeyValueTable;

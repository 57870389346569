import { Check, ChevronDown } from 'react-feather';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

interface Column {
    id: string;
    label: string;
    default: boolean;
    group: string;
    adminOnly: boolean;
}

interface ColumnDropdownProps {
    columns: Column[];
    visibleColumns: Record<string, boolean>;
    handleColumnChange: (columnId: string, checked: boolean) => void;
    isAdmin: boolean;
}

function ColumnDropdown({
    columns,
    visibleColumns,
    handleColumnChange,
    isAdmin,
}: ColumnDropdownProps) {
    // Filter out adminOnly columns for non-admin users
    const filteredColumns = columns.filter(
        (column) => !column.adminOnly || (column.adminOnly && isAdmin)
    );

    // Group columns by their group property
    const columnsByGroup = filteredColumns.reduce<Record<string, Column[]>>(
        (acc, column) => {
            if (!acc[column.group]) {
                acc[column.group] = [];
            }
            acc[column.group].push(column);
            return acc;
        },
        {}
    );

    return (
        <DropdownMenu.Root modal={false}>
            <DropdownMenu.Trigger className="flex items-center gap-2 px-3 py-2 rounded-lg border border-gray-200 dark:border-gray-100/20 hover:bg-gray-50 dark:hover:bg-gray-100/5 text-sm font-semibold">
                Columns
                <ChevronDown className="w-4 h-4" />
            </DropdownMenu.Trigger>
            <DropdownMenu.Portal>
                <DropdownMenu.Content className="max-h-64 overflow-y-scroll bg-white dark:bg-primary-dark-bg rounded-lg shadow-lg border border-gray-200 dark:border-gray-100/20 p-2 min-w-[200px]">
                    {Object.entries(columnsByGroup).map(
                        ([groupName, groupColumns], index, array) => (
                            <div key={groupName}>
                                <DropdownMenu.Label className="px-2 py-1.5 text-sm font-semibold">
                                    {groupName}
                                </DropdownMenu.Label>
                                {groupColumns.map((column) => (
                                    <DropdownMenu.CheckboxItem
                                        key={column.id}
                                        checked={visibleColumns[column.id]}
                                        onCheckedChange={(checked) =>
                                            handleColumnChange(
                                                column.id,
                                                checked
                                            )
                                        }
                                        onSelect={(event) => {
                                            event.preventDefault();
                                        }}
                                        className="relative flex items-center gap-2 px-8 py-1.5 select-none outline-none cursor-default hover:bg-gray-100 dark:hover:bg-gray-100/5 rounded text-sm"
                                    >
                                        <DropdownMenu.ItemIndicator className="absolute left-2">
                                            <Check className="w-4 h-4" />
                                        </DropdownMenu.ItemIndicator>
                                        {column.label}
                                    </DropdownMenu.CheckboxItem>
                                ))}
                                {index < array.length - 1 && (
                                    <DropdownMenu.Separator className="h-px bg-gray-200 dark:bg-gray-100/20 my-2" />
                                )}
                            </div>
                        )
                    )}
                </DropdownMenu.Content>
            </DropdownMenu.Portal>
        </DropdownMenu.Root>
    );
}

export default ColumnDropdown;

import { Link } from 'react-router-dom';
import { ArrowRight, ExternalLink } from 'react-feather';
import Callout from '../../Callout';
import CodeBlock from '../../CodeBlock';
import InlineCode from '../../InlineCode';

function EDSLInstallation() {
    const linkStyles =
        'text-blue-500 hover:text-blue-600 hover:underline dark:text-primary-dark-text-accent dark:hover:text-primary-dark-text-accent';

    return (
        <>
            <div className="space-y-2">
                <h1 className="font-bold text-4xl pb-2">Installing EDSL</h1>
                <p>
                    EDSL (
                    <span className="italic">
                        Expected Parrot Domain-Specific Language
                    </span>
                    ){' '}
                    is an open-source Python package for designing and conducting surveys with AI 
                    agents and large language models.
                    It simplifies the process of constructing questions, combining them into 
                    surveys with logic and rules, designing personas to answer the questions, and 
                    selecting language models to generate the responses.
                    It comes with built-in methods for analyzing results as datasets, extending
                    existing surveys with new data, and integrating data from many sources.
                </p>
            </div>
            <div className="space-y-2">
                <h2 className="font-bold text-3xl">Source code</h2>
                <p>
                    EDSL is compatible with Python versions 3.9-3.12.
                    You can find the source code and contribute to the project at{' '}
                    <a
                        href="https://github.com/expectedparrot/edsl"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`inline-flex items-center ${linkStyles}`}
                    >
                        GitHub
                    </a>{' '}
                    or download the library from{' '}
                    <a
                        href="https://pypi.org/project/edsl/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`inline-flex items-center ${linkStyles}`}
                    >
                        PyPI
                    </a>. Please see instructions below for installing the package from either source.
                </p>
                <br></br>
                <Callout title="Tip">
                    EDSL is in development. 
                    We recommend that you continually check for and install the latest 
                    version in order to access the latest features and bug fixes.
                </Callout>
            </div>
            <div className="space-y-2">
                <h2 className="font-bold text-3xl">Quickstart Installation</h2>
                <p>
                    To install the latest version of EDSL through pip:</p>
                <CodeBlock>pip install edsl</CodeBlock>
                <p>
                    To update to the latest version of EDSL:
                </p>
                <CodeBlock>pip install --upgrade edsl</CodeBlock>
            </div>
            <div className="space-y-2">
                <h2 className="font-bold text-3xl">Installing from source</h2>
                <p>
                    Run the following code to install EDSL from the source code at GitHub:
                </p>
                <CodeBlock>pip install git+https://github.com/expectedparrot/edsl.git@main</CodeBlock>
            </div>
            <div className="space-y-2">
                <h2 className="font-bold text-3xl">Using a virtual environment</h2>
                <p>
                    The installation steps above will install EDSL globally on your system. 
                    Sometimes, you may face problems with conflicts between EDSL and other libraries. 
                    To avoid such problems, we recommend that you use EDSL in a virtual environment.
                </p>
                <p>
                    Open your terminal and run the following command:
                </p>
                <CodeBlock>python3 -m venv myenv</CodeBlock>
                <p>
                    This will create a folder called{' '}
                    <InlineCode>myenv</InlineCode>. 
                    Next, activate your virtual environment:
                </p>
                <p> 
                    MacOS and Linux:
                </p>
                    <CodeBlock>source myenv/bin/activate</CodeBlock>
                <p> 
                    Windows:
                </p>
                <CodeBlock>myenv\Scripts\activate</CodeBlock>
                <p>
                    You can now install EDSL through pip within your virtual
                    environment:
                </p>
                    <CodeBlock>pip install edsl</CodeBlock>
                <p>
                    You will have access to EDSL while your virtual environment
                    is activated.
                </p>
                <p>
                    You can deactivate the virtual environment at any time by
                    running:
                </p>
                <CodeBlock>deactivate</CodeBlock>
                <p>
                    To delete the virtual environment, simply delete the{' '}
                    <InlineCode>myenv</InlineCode> folder.
                </p>
            </div>
            <div className="space-y-2">
                <h2 className="font-bold text-3xl">Next step: Store API keys</h2>
                <p>
                    →{' '}
                    After you have installed EDSL, see instructions for{' '}
                    <Link
                        to="/getting-started/edsl-api-keys"
                        className={linkStyles}
                    >
                        storing API keys
                    </Link>{' '}
                    for language models. Then try running examples in our{' '}
                    <a
                        href="https://docs.expectedparrot.com/en/latest/starter_tutorial.html"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`inline-flex items-center ${linkStyles}`}
                    >
                        starter tutorial
                    </a>.
                </p>
            </div>
        </>
    );
}

export default EDSLInstallation;

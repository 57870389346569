import { useState } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import * as Popover from '@radix-ui/react-popover';
import * as Tooltip from '@radix-ui/react-tooltip';
import * as VisuallyHidden from '@radix-ui/react-visually-hidden';
import { useFetcher } from 'react-router-dom';
import {
    Check,
    Copy,
    Download,
    EyeOff,
    Link,
    Lock,
    Mail,
    RefreshCw,
    Star,
    Users,
    X,
} from 'react-feather';
import ActionAlert from '../../../base/design-system/ActionAlert';
import { getRandomName, wordlist } from './wordlist';
import { useCopyToClipboard } from '../../../../hooks/useCopyToClipboard';

function VisibilityItem({
    objectId,
    name,
    value,
}: {
    objectId: string;
    name: string;
    value: string;
}) {
    const fetcher = useFetcher();

    return (
        <fetcher.Form method="post">
            <input type="hidden" name="visibility" value={value} />
            <input type="hidden" name="object_uuid" value={objectId} />
            <button
                className="w-24 select-none outline-none rounded-sm pl-4 pr-8 py-1 hover:bg-sky-600 hover:text-white"
                name="intent"
                value="change_visibility"
                type="submit"
            >
                {name}
            </button>
        </fetcher.Form>
    );
}

function ChangeVisibilityBtn({
    objectId,
    visibility,
    baseStyles,
}: {
    objectId: string;
    visibility: string;
    baseStyles: string;
}) {
    if (visibility === 'public') {
        return (
            <Popover.Root>
                <Popover.Trigger asChild>
                    <button
                        type="button"
                        className={`flex items-center border border rounded-lg text-green-600 hover:text-green-700 dark:hover:text-white ${baseStyles}`}
                    >
                        <Users
                            className="inline w-4 h-4 mr-2"
                            strokeWidth="2"
                        />
                        Public
                    </button>
                </Popover.Trigger>
                <Popover.Portal>
                    <Popover.Content
                        className="flex flex-col p-1.5 bg-white dark:bg-gray-700 rounded-lg shadow z-20"
                        sideOffset={5}
                    >
                        <Popover.Close asChild>
                            <VisibilityItem
                                objectId={objectId}
                                name="Private"
                                value="private"
                            />
                        </Popover.Close>
                        <Popover.Close asChild>
                            <VisibilityItem
                                objectId={objectId}
                                name="Unlisted"
                                value="unlisted"
                            />
                        </Popover.Close>
                    </Popover.Content>
                </Popover.Portal>
            </Popover.Root>
        );
    } else if (visibility === 'private') {
        return (
            <Popover.Root>
                <Popover.Trigger asChild>
                    <button
                        type="button"
                        className={`flex items-center border border rounded-lg text-red-600 hover:text-red-700 dark:hover:text-white ${baseStyles}`}
                    >
                        <Lock className="inline w-4 h-4 mr-2" strokeWidth="2" />
                        Private
                    </button>
                </Popover.Trigger>
                <Popover.Portal>
                    <Popover.Content
                        className="flex flex-col p-1.5 bg-white dark:bg-gray-700 rounded-lg shadow z-20"
                        sideOffset={5}
                    >
                        <Popover.Close asChild>
                            <VisibilityItem
                                objectId={objectId}
                                name="Public"
                                value="public"
                            />
                        </Popover.Close>
                        <Popover.Close asChild>
                            <VisibilityItem
                                objectId={objectId}
                                name="Unlisted"
                                value="unlisted"
                            />
                        </Popover.Close>
                    </Popover.Content>
                </Popover.Portal>
            </Popover.Root>
        );
    } else if (visibility === 'unlisted') {
        return (
            <Popover.Root>
                <Popover.Trigger asChild>
                    <button
                        type="button"
                        className={`flex items-center border border rounded-lg text-orange-600 hover:text-orange-700 dark:hover:text-white ${baseStyles}`}
                    >
                        <EyeOff
                            className="inline w-4 h-4 mr-2"
                            strokeWidth="2"
                        />
                        Unlisted
                    </button>
                </Popover.Trigger>
                <Popover.Portal>
                    <Popover.Content
                        className="flex flex-col p-1.5 bg-white dark:bg-gray-700 rounded-lg shadow z-20"
                        sideOffset={5}
                    >
                        <Popover.Close asChild>
                            <VisibilityItem
                                objectId={objectId}
                                name="Private"
                                value="private"
                            />
                        </Popover.Close>
                        <Popover.Close asChild>
                            <VisibilityItem
                                objectId={objectId}
                                name="Public"
                                value="public"
                            />
                        </Popover.Close>
                    </Popover.Content>
                </Popover.Portal>
            </Popover.Root>
        );
    }
}

function CopyCodeBtn({
    code,
    baseStyles,
}: {
    code: string;
    baseStyles: string;
}) {
    const [isCopied, copy] = useCopyToClipboard();

    function handleCopy(text: string) {
        copy(text).catch((error) => {
            console.error('Failed to copy!', error);
        });
    }

    return (
        <button
            type="button"
            onClick={() => handleCopy(code)}
            className={`flex items-center border rounded-lg hover:text-blue-700 dark:hover:text-white ${baseStyles}`}
        >
            <div
                className={`flex items-center inline w-4 h-4 transition-all motion-reduce:transition-none ${
                    isCopied ? 'scale-0 opacity-0' : 'scale-100 opacity-100'
                } `}
            >
                <Copy className="inline w-4 h-4" strokeWidth="2" />
            </div>
            <div
                className={`flex items-center w-4 h-4 absolute transition-all motion-reduce:transition-none ${
                    isCopied ? 'scale-100 opacity-100' : 'scale-0 opacity-0'
                } `}
            >
                <Check className="inline w-4 h-4" strokeWidth="2" />
            </div>
        </button>
    );
}

function DownloadBtn({
    objectId,
    baseStyles,
}: {
    objectId: string;
    baseStyles: string;
}) {
    const fetcher = useFetcher();

    return (
        <fetcher.Form className="flex" method="post">
            <input type="hidden" name="object_uuid" value={objectId} />
            <button
                type="submit"
                name="intent"
                value="download"
                className={`flex items-center me-2 border rounded-lg hover:text-blue-700 dark:hover:text-white ${baseStyles}`}
            >
                <Download className="inline w-4 h-4" strokeWidth="2" />
            </button>
        </fetcher.Form>
    );
}

function UnshareBtn({ objectId, email }: { objectId: string; email: string }) {
    const fetcher = useFetcher();

    return (
        <fetcher.Form method="post">
            <input type="hidden" name="email" value={email} />
            <input type="hidden" name="object_uuid" value={objectId} />
            <button
                type="submit"
                name="intent"
                value="unshare"
                className="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-3 py-2 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
            >
                Remove
            </button>
        </fetcher.Form>
    );
}

function DeleteModal({
    objectId,
    humanReadableObjectType,
    children,
}: {
    objectId: string;
    humanReadableObjectType: string;
    children: React.ReactNode;
}) {
    const fetcher = useFetcher();

    function getDeleteFeedback() {
        if (!fetcher.data) {
            return;
        }
        return (
            <p className="text-sm text-red-600 dark:text-red-500">
                {fetcher.data}
            </p>
        );
    }

    return (
        <Dialog.Root>
            <Dialog.Trigger asChild>{children}</Dialog.Trigger>
            <Dialog.Portal>
                <Dialog.Overlay className="fixed inset-0 bg-black/60 animate-dialog-overlay-show" />
                <Dialog.Content
                    className="w-[90vw] max-w-[450px] max-h-[85vh] fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-6 bg-white dark:bg-gray-700 rounded-md focus:outline-none animate-dialog-content-show"
                    aria-describedby={undefined}
                >
                    <div className="flex flex-col items-center text-sm text-center py-4 md:py-5">
                        <Dialog.Title asChild>
                            <h3 className="mb-5 text-lg font-normal">
                                Are you sure you want to delete{' '}
                                {humanReadableObjectType === 'results'
                                    ? 'these'
                                    : 'this'}{' '}
                                {humanReadableObjectType}?
                            </h3>
                        </Dialog.Title>
                        <div className="flex">
                            <Dialog.Close asChild>
                                <button
                                    type="button"
                                    className="py-2.5 px-5 me-4 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                                >
                                    No, cancel
                                </button>
                            </Dialog.Close>
                            <fetcher.Form method="post">
                                <input
                                    type="hidden"
                                    name="object_uuid"
                                    value={objectId}
                                />
                                <button
                                    type="submit"
                                    name="intent"
                                    value="delete"
                                    className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800 font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center"
                                >
                                    Yes, I'm sure
                                </button>
                            </fetcher.Form>
                        </div>
                        {getDeleteFeedback()}
                    </div>

                    <Dialog.Close asChild>
                        <button
                            className="absolute top-2.5 right-2.5 h-6 w-6 inline-flex justify-center align-center rounded-full"
                            aria-label="Close"
                        >
                            <X className="inline w-4 h-4" strokeWidth="2" />
                        </button>
                    </Dialog.Close>
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    );
}

function EditModal({
    objectId,
    humanReadableObjectType,
    description,
    children,
}: {
    objectId: string;
    humanReadableObjectType: string;
    description: string;
    children: React.ReactNode;
}) {
    const fetcher = useFetcher();

    return (
        <Dialog.Root>
            <Dialog.Trigger asChild>{children}</Dialog.Trigger>
            <Dialog.Portal>
                <Dialog.Overlay className="fixed inset-0 bg-black/60 animate-dialog-overlay-show" />
                <Dialog.Content className="w-[90vw] max-w-[450px] max-h-[85vh] fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-6 bg-white dark:bg-gray-700 rounded-md focus:outline-none animate-dialog-content-show">
                    <Dialog.Title asChild>
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            Edit your {humanReadableObjectType}
                        </h3>
                    </Dialog.Title>
                    <VisuallyHidden.Root>
                        <Dialog.Description>
                            Make changes to your object's description.
                        </Dialog.Description>
                    </VisuallyHidden.Root>
                    <div className="text-sm py-4 md:py-5">
                        <fetcher.Form method="post">
                            <div>
                                <label
                                    htmlFor={`edit-description-input-${objectId}`}
                                    className="block mb-3 text-base font-medium text-gray-900 dark:text-white"
                                >
                                    Description
                                </label>
                                <textarea
                                    id={`edit-description-input-${objectId}`}
                                    rows={4}
                                    name="description"
                                    className="block resize-none p-2.5 w-full text-sm bg-gray-50 focus:outline-none border rounded-lg border-gray-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700/20 dark:border-gray-500 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:placeholder-gray-400"
                                    defaultValue={description}
                                />
                                <input
                                    type="hidden"
                                    name="object_uuid"
                                    value={objectId}
                                />
                            </div>
                            <button
                                type="submit"
                                name="intent"
                                value="edit"
                                className="w-full mt-3 mb-3 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            >
                                Save
                            </button>
                        </fetcher.Form>
                        <ActionAlert response={fetcher.data} />
                    </div>

                    <Dialog.Close asChild>
                        <button
                            className="absolute top-2.5 right-2.5 h-6 w-6 inline-flex justify-center align-center rounded-full"
                            aria-label="Close"
                        >
                            <X className="inline w-4 h-4" strokeWidth="2" />
                        </button>
                    </Dialog.Close>
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    );
}

function UpdateAliasForm({
    objectId,
    objectAlias,
}: {
    objectId: string;
    objectAlias: string;
}) {
    const fetcher = useFetcher();
    const [newAlias, setNewAlias] = useState(() => {
        if (objectAlias === null) {
            return getRandomName(wordlist);
        }
        return objectAlias;
    });

    function generateNewAlias() {
        const newAlias = getRandomName(wordlist);
        setNewAlias(newAlias);
    }

    return (
        <div>
            <fetcher.Form method="post">
                <div className="flex gap-2">
                    <VisuallyHidden.Root>
                        <label htmlFor={`update-alias-input-${objectId}`}>
                            New alias
                        </label>
                    </VisuallyHidden.Root>
                    <input
                        id={`update-alias-input-${objectId}`}
                        name="alias"
                        className="block p-2.5 w-full text-sm bg-gray-50 focus:outline-none border rounded-lg border-gray-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700/20 dark:border-gray-500 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:placeholder-gray-400"
                        value={newAlias}
                        onChange={(event) => setNewAlias(event.target.value)}
                    />
                    <input type="hidden" name="object_uuid" value={objectId} />
                    <Tooltip.Provider>
                        <Tooltip.Root delayDuration={0}>
                            <Tooltip.Trigger asChild>
                                <button
                                    type="button"
                                    onClick={generateNewAlias}
                                    className={`flex items-center me-2 border rounded-lg hover:text-blue-700 dark:hover:text-white py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white border-gray-200 hover:bg-gray-100 focus:z-10 focus:ring focus:ring-gray-100 dark:focus:ring-gray-500 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700`}
                                >
                                    <RefreshCw
                                        className="inline w-4 h-4"
                                        strokeWidth="2"
                                    />
                                </button>
                            </Tooltip.Trigger>
                            <Tooltip.Portal>
                                <Tooltip.Content
                                    className="px-3 py-2 select-none text-sm font-medium text-white bg-gray-900 dark:bg-gray-700 rounded-lg shadow-sm"
                                    sideOffset={5}
                                >
                                    Generate new alias
                                    <Tooltip.Arrow className="fill-gray-900 dark:fill-gray-700" />
                                </Tooltip.Content>
                            </Tooltip.Portal>
                        </Tooltip.Root>
                    </Tooltip.Provider>
                </div>
                <button
                    type="submit"
                    name="intent"
                    value="update_alias"
                    className="w-full mt-3 mb-3 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                    Update alias
                </button>
            </fetcher.Form>
            <ActionAlert response={fetcher.data} />
        </div>
    );
}

function CopyIdLinkForm({ objectId }: { objectId: string }) {
    const [isCopied, copy] = useCopyToClipboard();

    const rootUrl = window.location.origin;
    const linkToContent = `${rootUrl}/content/${objectId}`;

    function handleCopy(text: string) {
        copy(text).catch((error) => {
            console.error('Failed to copy!', error);
        });
    }

    return (
        <div className="flex gap-2">
            <VisuallyHidden.Root>
                <label htmlFor={`id-link-input-${objectId}`}>UUID link</label>
            </VisuallyHidden.Root>
            <input
                id={`id-link-input-${objectId}`}
                type="text"
                value={linkToContent}
                readOnly
                className="w-full bg-white focus:outline-none border border-gray-400 rounded-md focus:ring-1 focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700/20 dark:border-gray-500 dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
            <button
                type="button"
                onClick={() => handleCopy(linkToContent)}
                aria-label="Copy UUID link"
                className={`flex items-center me-2 border rounded-lg hover:text-blue-700 dark:hover:text-white py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white border-gray-200 hover:bg-gray-100 focus:z-10 focus:ring focus:ring-gray-100 dark:focus:ring-gray-500 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700`}
            >
                <div
                    className={`flex items-center inline w-4 h-4 transition-all motion-reduce:transition-none ${
                        isCopied ? 'scale-0 opacity-0' : 'scale-100 opacity-100'
                    } `}
                >
                    <Copy className="inline w-4 h-4" strokeWidth="2" />
                </div>
                <div
                    className={`flex items-center w-4 h-4 absolute transition-all motion-reduce:transition-none  ${
                        isCopied ? 'scale-100 opacity-100' : 'scale-0 opacity-0'
                    } `}
                >
                    <Check className="inline w-4 h-4" strokeWidth="2" />
                </div>
            </button>
        </div>
    );
}

function CopyAliasLinkForm({
    objectId,
    ownerUsername,
    objectAlias,
}: {
    objectId: string;
    ownerUsername: string;
    objectAlias: string;
}) {
    const [isCopied, copy] = useCopyToClipboard();

    const rootUrl = window.location.origin;
    const linkToContent = `${rootUrl}/${ownerUsername}/${objectAlias}`;

    function handleCopy(text: string) {
        copy(text).catch((error) => {
            console.error('Failed to copy!', error);
        });
    }

    return (
        <div className="flex gap-2">
            <VisuallyHidden.Root>
                <label htmlFor={`alias-link-input-${objectId}`}>
                    Alias link
                </label>
            </VisuallyHidden.Root>
            <input
                id={`alias-link-input-${objectId}`}
                type="text"
                value={linkToContent}
                readOnly
                className="w-full bg-white focus:outline-none border border-gray-400 rounded-md focus:ring-1 focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700/20 dark:border-gray-500 dark:focus:ring-blue-500 dark:focus:border-blue-500"
            />
            <button
                type="button"
                onClick={() => handleCopy(linkToContent)}
                aria-label="Copy alias link"
                className={`flex items-center me-2 border rounded-lg hover:text-blue-700 dark:hover:text-white py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white border-gray-200 hover:bg-gray-100 focus:z-10 focus:ring focus:ring-gray-100 dark:focus:ring-gray-500 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700`}
            >
                <div
                    className={`flex items-center inline w-4 h-4 transition-all motion-reduce:transition-none ${
                        isCopied ? 'scale-0 opacity-0' : 'scale-100 opacity-100'
                    } `}
                >
                    <Copy className="inline w-4 h-4" strokeWidth="2" />
                </div>
                <div
                    className={`flex items-center w-4 h-4 absolute transition-all motion-reduce:transition-none ${
                        isCopied ? 'scale-100 opacity-100' : 'scale-0 opacity-0'
                    } `}
                >
                    <Check className="inline w-4 h-4" strokeWidth="2" />
                </div>
            </button>
        </div>
    );
}

function LinkModal({
    objectId,
    humanReadableObjectType,
    objectAlias,
    ownerUsername,
    isOwnedByUser,
    children,
}: {
    objectId: string;
    humanReadableObjectType: string;
    objectAlias: string;
    ownerUsername: string;
    isOwnedByUser: boolean;
    children: React.ReactNode;
}) {
    return (
        <Dialog.Root>
            <Dialog.Trigger asChild>{children}</Dialog.Trigger>
            <Dialog.Portal>
                <Dialog.Overlay className="fixed inset-0 bg-black/60 animate-dialog-overlay-show" />
                <Dialog.Content className="w-[90vw] max-w-[450px] max-h-[85vh] overflow-y-auto fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-6 bg-white dark:bg-gray-700 rounded-md focus:outline-none animate-dialog-content-show">
                    <Dialog.Title asChild>
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            Share link to {humanReadableObjectType}
                        </h3>
                    </Dialog.Title>
                    <VisuallyHidden.Root>
                        <Dialog.Description>
                            Share your object with an ID-based link, or give
                            your object a prettier link by editing the alias.
                        </Dialog.Description>
                    </VisuallyHidden.Root>
                    <div className="text-sm py-4 md:py-5">
                        <p className="mb-3 text-base font-medium text-gray-900 dark:text-white">
                            Copy UUID link
                        </p>
                        <CopyIdLinkForm objectId={objectId} />
                        <div className="relative my-6">
                            <div className="absolute inset-0 flex items-center">
                                <div className="w-full border-t border-gray-300 dark:border-gray-600"></div>
                            </div>
                            <div className="relative flex justify-center text-sm">
                                <span className="px-2 bg-white dark:bg-gray-700">
                                    OR
                                </span>
                            </div>
                        </div>
                        <p className="my-3 text-base font-medium text-gray-900 dark:text-white">
                            Copy alias link
                        </p>
                        <CopyAliasLinkForm
                            objectId={objectId}
                            ownerUsername={ownerUsername}
                            objectAlias={objectAlias}
                        />
                        {isOwnedByUser && (
                            <div>
                                <p className="my-3 text-base font-medium text-gray-900 dark:text-white">
                                    Update alias
                                </p>
                                <UpdateAliasForm
                                    objectId={objectId}
                                    objectAlias={objectAlias}
                                />
                            </div>
                        )}
                    </div>
                    <Dialog.Close asChild>
                        <button
                            className="absolute top-2.5 right-2.5 h-6 w-6 inline-flex justify-center align-center rounded-full"
                            aria-label="Close"
                        >
                            <X className="inline w-4 h-4" strokeWidth="2" />
                        </button>
                    </Dialog.Close>
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    );
}

function ShareObjectForm({ objectId }: { objectId: string }) {
    const fetcher = useFetcher();

    return (
        <div>
            <fetcher.Form method="post">
                <div>
                    <label
                        htmlFor={`share-input-${objectId}`}
                        className="block mb-3 text-base font-medium text-gray-900 dark:text-white"
                    >
                        Username or email
                    </label>
                    <input
                        type="text"
                        name="username_or_email"
                        id={`share-input-${objectId}`}
                        className="block w-full p-2.5 mb-3 bg-gray-50 focus:outline-none border rounded-lg border-gray-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700/20 dark:border-gray-500 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:placeholder-gray-400"
                        placeholder="name@company.com"
                        required
                    />
                    <input type="hidden" name="object_uuid" value={objectId} />
                </div>
                <button
                    type="submit"
                    name="intent"
                    value="share"
                    className="w-full mb-3 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                    Share
                </button>
            </fetcher.Form>
            <ActionAlert response={fetcher.data} />
        </div>
    );
}

function ShareModal({
    objectId,
    humanReadableObjectType,
    isOwnedByUser,
    sharedWith,
    tempSharedWith,
    children,
}: {
    objectId: string;
    humanReadableObjectType: string;
    isOwnedByUser: boolean;
    sharedWith: { username: string; email: string; is_owner: boolean }[];
    tempSharedWith: { username: string; email: string; is_owner: boolean }[];
    children: React.ReactNode;
}) {
    return (
        <Dialog.Root>
            <Dialog.Trigger asChild>{children}</Dialog.Trigger>
            <Dialog.Portal>
                <Dialog.Overlay className="fixed inset-0 bg-black/60 animate-dialog-overlay-show" />
                <Dialog.Content className="w-[90vw] max-w-[450px] max-h-[85vh] overflow-y-auto fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-6 bg-white dark:bg-gray-700 rounded-md focus:outline-none animate-dialog-content-show">
                    <Dialog.Title asChild>
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            Share your {humanReadableObjectType}
                        </h3>
                    </Dialog.Title>
                    <VisuallyHidden.Root>
                        <Dialog.Description>
                            Share your object via username or email.
                        </Dialog.Description>
                    </VisuallyHidden.Root>
                    <div className="text-sm py-4 md:py-5">
                        <ShareObjectForm objectId={objectId} />
                        {/* Don't show sharing details if the current user is not the object owner */}
                        {isOwnedByUser && (
                            <>
                                <p className="block mt-4 mb-2 text-base font-medium text-gray-900 dark:text-white">
                                    People with access
                                </p>
                                <ul>
                                    {sharedWith.map((user, index) => (
                                        <li key={index}>
                                            <div className="flex justify-between items-baseline border-t pt-2 border-gray-200 dark:border-gray-600">
                                                <div className="font-medium">
                                                    {user.username ||
                                                        'No username selected'}
                                                </div>
                                                {user.is_owner ? (
                                                    <div className="text-gray-900 bg-white font-medium rounded-lg text-sm px-3 py-2 me-2 mb-2">
                                                        Owner
                                                    </div>
                                                ) : (
                                                    <UnshareBtn
                                                        objectId={objectId}
                                                        email={user.email}
                                                    />
                                                )}
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                                <ul>
                                    {tempSharedWith.map((user, index) => (
                                        <li key={index}>
                                            <div className="flex justify-between items-baseline border-t pt-2 border-gray-200 dark:border-gray-600">
                                                <div className="font-medium">
                                                    <Mail
                                                        className="inline w-4 h-4 mr-2"
                                                        strokeWidth="2"
                                                    />
                                                    {user.email}
                                                </div>
                                                {
                                                    <UnshareBtn
                                                        objectId={objectId}
                                                        email={user.email}
                                                    />
                                                }
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </>
                        )}
                    </div>

                    <Dialog.Close asChild>
                        <button
                            className="absolute top-2.5 right-2.5 h-6 w-6 inline-flex justify-center align-center rounded-full"
                            aria-label="Close"
                        >
                            <X className="inline w-4 h-4" strokeWidth="2" />
                        </button>
                    </Dialog.Close>
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    );
}

function FavoriteBtn({
    objectId,
    isStarredByUser,
    baseStyles,
}: {
    objectId: string;
    isStarredByUser: boolean;
    baseStyles: string;
}) {
    const fetcher = useFetcher();

    return (
        <fetcher.Form method="post" className="flex">
            <input type="hidden" name="object_uuid" value={objectId} />
            <button
                type="submit"
                name="intent"
                value="toggle_star"
                className={`flex items-center border rounded-s-lg hover:text-blue-700 dark:hover:text-white ${baseStyles}`}
            >
                <Star
                    className={`inline w-4 h-4 ${
                        isStarredByUser && 'fill-black'
                    }`}
                    strokeWidth="2"
                />
            </button>
        </fetcher.Form>
    );
}

export {
    ChangeVisibilityBtn,
    CopyCodeBtn,
    DeleteModal,
    DownloadBtn,
    EditModal,
    FavoriteBtn,
    LinkModal,
    ShareModal,
};

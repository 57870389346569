import { Globe, Linkedin, Mail } from 'react-feather';
import ContentContainer from '../base/ContentContainer';

interface CofounderDetails {
    id: number;
    name: string;
    imgSrc: string;
    imgAlt: string;
    email?: string;
    linkedIn?: string;
    personalSite?: string;
}

function About() {

    return (
        <ContentContainer additionalClasses="text-center">
            <div className="max-w-4xl mx-auto mt-16">
                <h1 className="text-3xl sm:text-5xl font-bold leading-[1.25]">
                    We're building tools for AI-powered research.
                </h1>
                <p className="text-xl mt-12">
                    Our work draws on recent{' '}
                    <a
                        href="https://docs.expectedparrot.com/en/latest/papers.html"
                        target="_blank"
                        className="underline hover:text-green-700 dark:hover:text-primary-dark-text-accent"
                    >
                        research
                    </a>{' '}
                    demonstrating that AI can be used to simulate surveys,
                    experiments and other empirical studies. We believe that
                    this breakthrough has far-ranging applications for user and
                    market research. Our goal is to make conducting credible
                    AI-powered research easy.
                </p>
            </div>
            <div className="w-full mt-8 px-5 sm:px-16">
                <div className="max-w-3xl mx-auto">
                    <p className="text-xl mt-4">
                        We're located at the{' '}
                        <a
                            href="https://maps.app.goo.gl/LaPNWQr5c26R8qee9"
                            target="_blank"
                            className="underline hover:text-green-700 dark:hover:text-primary-dark-text-accent"
                        >
                            Cambridge Innovation Center
                        </a>{' '}
                        and backed by{' '}
                        <a
                            href="https://www.bloombergbeta.com/"
                            className="underline hover:text-green-700 dark:hover:text-primary-dark-text-accent"
                            target="_blank"
                        >
                            Bloomberg Beta
                        </a>
                        . Interested in working with us? Send us a message on{' '}
                        <a
                            className="underline hover:text-green-700 dark:hover:text-primary-dark-text-accent"
                            href="https://discord.com/invite/mxAYkjfy9m"
                        >
                            Discord
                        </a>{' '} 
                        or email us at{' '}
                        <a
                            className="underline hover:text-green-700 dark:hover:text-primary-dark-text-accent"
                            href="mailto:info@expectedparrot.com"
                        >
                            info@expectedparrot.com
                        </a>
                        .
                    </p>
                </div>
            </div>
        </ContentContainer>
    );
}

export default About;

import { redirect } from 'react-router-dom';

export async function action({ request }) {
    let data;
    let intent;

    const contentType = request.headers.get('Content-Type');

    if (contentType && contentType.includes('application/json')) {
        // console.log('Handling JSON data...');
        data = await request.json();
        intent = data.intent;
    } else {
        // console.log('Handling form data...');
        data = await request.formData();
        intent = data.get('intent');
    }

    let response: Response;

    if (intent === 'create_job') {
        const projectId = data.get('projectId');
        const agentList = data.get('agentList');
        const scenarioList = data.get('scenarioList');
        const modelList = data.get('modelList');
        const formData = new FormData();
        formData.append('project_uuid', projectId);
        formData.append('json_agent_list', agentList);
        formData.append('json_scenario_list', scenarioList);
        formData.append('json_model_list', modelList);
        response = await fetch(`/api/projects/create-job`, {
            method: 'POST',
            body: formData,
        });
    } else if (intent === 'retry_job') {
        response = await fetch(`/api/home/remote-inference`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                job_uuid: data.get('job_uuid'),
                status: 'queued',
            }),
        });
    } else if (intent === 'cancel_job') {
        response = await fetch(`/api/home/remote-inference`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                job_uuid: data.get('job_uuid'),
                status: 'cancelling',
            }),
        });
    } else if (intent === 'find_agent_list') {
        const agentListUrl = data.get('agent_list_url');
        response = await fetch(`/api/find-agent-list`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ object_url: agentListUrl }),
        });
    } else if (intent === 'find_scenario_list') {
        const scenarioListUrl = data.get('scenario_list_url');
        response = await fetch(`/api/find-scenario-list`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ object_url: scenarioListUrl }),
        });
    } else if (intent === 'find_model_list') {
        const modelListUrl = data.get('model_list_url');
        response = await fetch(`/api/find-model-list`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ object_url: modelListUrl }),
        });
    } else if (intent === 'edit_project') {
        const projectId = data.get('project_uuid');
        response = await fetch(`/api/projects/${projectId}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: data.get('project_name'),
            }),
        });
    } else if (intent === 'delete_project') {
        const projectId = data.get('project_uuid');
        response = await fetch(`/api/projects/${projectId}`, {
            method: 'DELETE',
        });
    }

    if (response.ok && intent === 'create_job') {
        const successData = await response.json();
        return successData.message;
    } else if (response.ok && intent === 'retry_job') {
        return 'Success! Job added to queue.';
    } else if (response.ok && intent === 'cancel_job') {
        return 'Success! Cancellation request sent.';
    } else if (response.ok && intent === 'find_agent_list') {
        const successData = await response.json();
        return {
            success: true,
            message: 'Retrieved agent list.',
            num_agents: successData.num_agents,
            description: successData.description,
            uuid: successData.uuid,
            traits: successData.traits,
            first_five_rows: successData.first_five_rows,
        };
    } else if (response.ok && intent === 'find_scenario_list') {
        const successData = await response.json();
        return {
            success: true,
            message: 'Retrieved scenario list.',
            num_scenarios: successData.num_scenarios,
            description: successData.description,
            uuid: successData.uuid,
            keys: successData.keys,
            first_five_rows: successData.first_five_rows,
        };
    } else if (response.ok && intent === 'find_model_list') {
        const successData = await response.json();
        return {
            success: true,
            message: 'Retrieved model list.',
            num_models: successData.num_models,
            description: successData.description,
            uuid: successData.uuid,
            models: successData.models,
        };
    } else if (response.ok && intent === 'edit_project') {
        const successData = await response.json();
        return { success: true, message: successData.message };
    } else if (response.ok && intent === 'delete_project') {
        return redirect('/home/projects');
    } else if (
        !response.ok &&
        (intent === 'edit_project' ||
            intent === 'find_agent_list' ||
            intent === 'find_scenario_list' ||
            intent === 'find_model_list')
    ) {
        // These intents have been added later, so they use the new actions structure
        // TODO: Migrate other actions to the { success: bool, message: str } structure
        if (response.status === 422) {
            return { success: false, message: 'Pydantic error.' };
        } else if (response.status === 504) {
            return { success: false, message: 'No response from server.' };
        } else {
            const errorData = await response.json();
            return { success: false, message: errorData.detail };
        }
    } else if (response.status === 422) {
        const errorData = await response.json();
        console.log(errorData);
        return 'Pydantic error.';
    } else if (response.status === 504) {
        return 'No response from server.';
    } else {
        const errorData = await response.json();
        return errorData.detail;
    }
}

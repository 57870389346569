import { Link } from 'react-router-dom';
import { ArrowRight } from 'react-feather';

function CreateIndex() {
    const lightCreateBtnStyles =
        'group self-stretch w-full flex flex-col gap-3 bg-white text-left border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 rounded-lg px-5 py-2.5';
    const darkCreateBtnStyles =
        'dark:bg-gray-700/20 dark:text-primary-dark-text dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700';
    const createBtnStyles = `${lightCreateBtnStyles} ${darkCreateBtnStyles}`;

    return (
        <div className="p-8 space-y-4 m-auto border-solid border-2 border-gray-300 dark:border-primary-dark-border rounded-lg text-sm shadow-md">
            <Link className={createBtnStyles} to="/create/survey">
                <p className="flex items-center gap-1 font-medium text-lg">
                    Survey
                    <ArrowRight className="w-4 h-4 opacity-0 group-hover:opacity-100 group-hover:translate-x-1 transition-all motion-reduce:transition-none duration-150" />
                </p>
                Create a survey with our interactive builder
            </Link>
            <Link className={createBtnStyles} to="/create/agent-list">
                <p className="flex items-center gap-1 font-medium text-lg">
                    Agent List
                    <ArrowRight className="w-4 h-4 opacity-0 group-hover:opacity-100 group-hover:translate-x-1 transition-all motion-reduce:transition-none duration-150" />
                </p>
                Create a list of agents with traits and values, or upload a CSV
                file
            </Link>
            <Link className={createBtnStyles} to="/create/scenario-list">
                <p className="flex items-center gap-1 font-medium text-lg">
                    Scenario List
                    <ArrowRight className="w-4 h-4 opacity-0 group-hover:opacity-100 group-hover:translate-x-1 transition-all motion-reduce:transition-none duration-150" />
                </p>
                Create a list of scenarios with fields and values, or upload a
                CSV file
            </Link>
            <Link className={createBtnStyles} to="/create/project">
                <p className="flex items-center gap-1 font-medium text-lg">
                    Project
                    <ArrowRight className="w-4 h-4 opacity-0 group-hover:opacity-100 group-hover:translate-x-1 transition-all motion-reduce:transition-none duration-150" />
                </p>
                Add a survey to a project to begin collecting responses
            </Link>
        </div>
    );
}

export default CreateIndex;

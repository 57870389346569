export { default } from './routes/CreateIndex';
export {
    default as AgentList,
    action as agentListAction,
} from './routes/AgentList';
export {
    default as ScenarioList,
    action as scenarioListAction,
} from './routes/ScenarioList';
export { default as Survey, action as surveyAction } from './routes/Survey';
export { default as Project, action as projectAction } from './routes/Project';

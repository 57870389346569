import { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import { Check, Clock, X } from 'react-feather';
import JobHistory from './JobHistory';
import { CancelBtn, ProgressLink, RetryBtn } from './ActionButtons';
import { formatDate } from '../../../utils';
import { Job } from '../../../types';

interface RemoteJobTableProps {
    isAdmin?: boolean;
    jobs: Job[];
}

function StatusBadge({ status }: { status: string }) {
    const getStatusStyles = (status: string) => {
        switch (status) {
            case 'queued':
            case 'running':
            case 'cancelling':
                return 'rounded-lg bg-yellow-100 text-yellow-800 dark:bg-yellow-400/15 dark:text-yellow-400';
            case 'cancelled':
            case 'failed':
                return 'rounded-lg bg-red-100 text-red-800 dark:bg-red-400/15 dark:text-red-400';
            case 'completed':
                return 'rounded-lg bg-green-100 text-green-800 dark:bg-green-400/15 dark:text-green-400';
            default:
                return 'rounded-lg bg-gray-100 text-gray-800 dark:bg-gray-400/15 dark:text-gray-300';
        }
    };

    const getStatusIcon = (status: string) => {
        switch (status) {
            case 'queued':
            case 'running':
            case 'cancelling':
                return <Clock className="w-4 h-4" />;
            case 'cancelled':
            case 'failed':
                return <X className="w-4 h-4" />;
            case 'completed':
                return <Check className="w-4 h-4" />;
            default:
                return null;
        }
    };

    return (
        <div
            className={`flex items-center gap-2 px-2 py-1 rounded-md text-sm font-semibold ${getStatusStyles(
                status
            )}`}
        >
            {getStatusIcon(status)}
            {status}
        </div>
    );
}

function RemoteJobTable({ jobs, isAdmin = false }: RemoteJobTableProps) {
    const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set());

    function toggleRow(id: string) {
        setExpandedRows((prev) => {
            const newSet = new Set(prev);
            if (newSet.has(id)) {
                newSet.delete(id);
            } else {
                newSet.add(id);
            }
            return newSet;
        });
    }

    function getResults(
        resultsStatus: string,
        resultsUUID: string,
        isAdmin: boolean
    ) {
        if (resultsStatus === 'completed') {
            const resultsLink = isAdmin
                ? `/admin/objects/${resultsUUID}`
                : `/content/${resultsUUID}`;
            return (
                <Link
                    to={resultsLink}
                    target="_blank"
                    className="text-blue-500 hover:text-blue-600 dark:text-primary-dark-text-accent dark:hover:text-primary-dark-text-accent"
                >
                    View
                </Link>
            );
        } else if (resultsStatus === 'not applicable') {
            return 'None';
        } else if (resultsStatus === 'deleted') {
            return 'Deleted by user';
        } else if (resultsStatus === 'pending') {
            return 'Pending';
        }
    }

    return (
        <div className="mb-8 overflow-x-auto rounded-lg">
            <div className="relative">
                <table className="min-w-full text-sm relative">
                    <thead className="bg-white dark:bg-primary-dark-bg sticky border-b dark:border-gray-100/20 top-0">
                        <tr>
                            <th className="py-2 px-4 text-left w-40 font-semibold">
                                Last Queued
                            </th>
                            {isAdmin && (
                                <th className="py-2 px-4 text-left w-40 font-semibold">
                                    Owner
                                </th>
                            )}
                            <th className="py-2 px-4 text-left w-40 font-semibold">
                                Job Description
                            </th>
                            <th className="py-2 px-4 text-center w-24 font-semibold">
                                Results
                            </th>
                            <th className="py-2 px-4 text-left w-24 font-semibold">
                                Status
                            </th>
                            <th className="py-2 px-4 text-center w-24 font-semibold">
                                Credits
                            </th>
                            <th className="py-2 px-4 text-center w-24 font-semibold">
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody className="align-top">
                        {jobs.map((job, index) => (
                            <Fragment key={index}>
                                <tr
                                    onClick={() => toggleRow(job.job_uuid)}
                                    className={`border-y dark:border-gray-100/20 dark:text-secondary-dark-text cursor-pointer`}
                                >
                                    <td className="py-2 px-4">
                                        {formatDate(
                                            job.last_queued_ts,
                                            'MMMM D, YYYY h:mm:ss A'
                                        )}
                                    </td>
                                    {isAdmin && (
                                        <td className="py-2 px-4">
                                            {job.owner_email}
                                        </td>
                                    )}
                                    <td className="py-2 px-4">
                                        {job.description ||
                                            'No description provided'}
                                    </td>
                                    <td className="py-2 px-4 text-center">
                                        {getResults(
                                            job.results_status,
                                            job.results_uuid,
                                            isAdmin
                                        )}
                                    </td>
                                    <td className="py-2 px-4 capitalize">
                                        <StatusBadge status={job.status} />
                                    </td>
                                    <td className="py-2 px-4 text-center">
                                        {job.price}
                                    </td>
                                    <td className="py-2 px-4 text-center space-x-2">
                                        {job.status === 'failed' && (
                                            <RetryBtn jobId={job.job_uuid} />
                                        )}
                                        {/* {(job.status === 'queued' || job.status === 'running') && 
                                <CancelBtn jobId={job.job_uuid} />} */}
                                        {job.has_progress_data === true && (
                                            <ProgressLink
                                                jobId={job.job_uuid}
                                                isAdmin={isAdmin}
                                            />
                                        )}
                                    </td>
                                </tr>
                                {expandedRows.has(job.job_uuid) && (
                                    <tr className="max-h-[40vh] text-left border-y dark:border-gray-100/20">
                                        <JobHistory
                                            job={job}
                                            isAdmin={isAdmin}
                                        />
                                    </tr>
                                )}
                            </Fragment>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default RemoteJobTable;

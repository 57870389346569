import { NavLink, useNavigate } from 'react-router-dom';
import { Box, LogOut, Star, User } from 'react-feather';
import SidebarContainer from '../base/SidebarContainer';
import { logoutAsAdmin } from '../../helpers/auth.helpers';

function Sidebar() {
    const navigate = useNavigate();

    function handleAdminLogout() {
        logoutAsAdmin().then((responseMsg: string) => {
            if (responseMsg === 'Admin logout successful!') {
                navigate('/admin/login');
            } else {
                console.log('Logout unsuccessful.');
            }
        });
    }

    const activeNavLinkStyles =
        'text-blue-600 dark:text-primary-dark-text-accent font-bold';
    const inactiveNavLinkStyles =
        'rounded-md hover:bg-gray-100 dark:hover:bg-gray-50/10 dark:text-primary-dark-text-accent';

    return (
        <SidebarContainer>
            <nav className="space-y-2 text-blue-400 text-sm">
                <div className="-space-y-2">
                    <div className="inline-flex items-center p-2 text-gray-500 dark:text-gray-300 font-semibold">
                        <Box className="inline w-4 h-4 mr-1" strokeWidth="2" />
                        Content
                    </div>
                    <NavLink
                        to="/admin/users"
                        className={({ isActive }) =>
                            isActive
                                ? `block p-2 ${activeNavLinkStyles}`
                                : `block p-2 ${inactiveNavLinkStyles}`
                        }
                    >
                        Users
                    </NavLink>
                    <NavLink
                        to="/admin/objects"
                        className={({ isActive }) =>
                            isActive
                                ? `block p-2 ${activeNavLinkStyles}`
                                : `block p-2 ${inactiveNavLinkStyles}`
                        }
                    >
                        Objects
                    </NavLink>
                </div>
                <div className="-space-y-2">
                    <div className="inline-flex items-center p-2 text-gray-500 dark:text-gray-300 font-semibold">
                        <Star className="inline w-4 h-4 mr-1" strokeWidth="2" />
                        Services
                    </div>
                    <NavLink
                        to="/admin/remote-cache"
                        className={({ isActive }) =>
                            isActive
                                ? `block p-2 ${activeNavLinkStyles}`
                                : `block p-2 ${inactiveNavLinkStyles}`
                        }
                    >
                        Remote Cache
                    </NavLink>
                    <NavLink
                        to="/admin/remote-inference"
                        className={({ isActive }) =>
                            isActive
                                ? `block p-2 ${activeNavLinkStyles}`
                                : `block p-2 ${inactiveNavLinkStyles}`
                        }
                    >
                        Remote Inference
                    </NavLink>
                    <NavLink
                        to="/admin/pricing"
                        className={({ isActive }) =>
                            isActive
                                ? `block p-2 ${activeNavLinkStyles}`
                                : `block p-2 ${inactiveNavLinkStyles}`
                        }
                    >
                        Pricing
                    </NavLink>
                </div>
                <div className="-space-y-2">
                    <div className="inline-flex items-center p-2 text-gray-500 dark:text-gray-300 font-semibold">
                        <User className="inline w-4 h-4 mr-1" strokeWidth="2" />
                        Admin Functions
                    </div>
                    <NavLink
                        to="/admin/stale-objects"
                        className={({ isActive }) =>
                            isActive
                                ? `block p-2 ${activeNavLinkStyles}`
                                : `block p-2 ${inactiveNavLinkStyles}`
                        }
                    >
                        Stale Object Check
                    </NavLink>
                    <NavLink
                        to="/admin/user-statistics"
                        className={({ isActive }) =>
                            isActive
                                ? `block p-2 ${activeNavLinkStyles}`
                                : `block p-2 ${inactiveNavLinkStyles}`
                        }
                    >
                        User Statistics
                    </NavLink>
                    <NavLink
                        to="/admin/config-vars"
                        className={({ isActive }) =>
                            isActive
                                ? `block p-2 ${activeNavLinkStyles}`
                                : `block p-2 ${inactiveNavLinkStyles}`
                        }
                    >
                        Config Vars
                    </NavLink>
                    <NavLink
                        to="/admin/edsl-version"
                        className={({ isActive }) =>
                            isActive
                                ? `block p-2 ${activeNavLinkStyles}`
                                : `block p-2 ${inactiveNavLinkStyles}`
                        }
                    >
                        EDSL Version
                    </NavLink>
                </div>
                <div className="-space-y-2">
                    <button
                        onClick={handleAdminLogout}
                        type="button"
                        className="w-full inline-flex items-center rounded-md p-2 text-red-700 hover:bg-gray-100 dark:hover:bg-gray-50/10 dark:text-amber-400 dark:hover:text-amber-500"
                    >
                        <LogOut
                            className="inline w-4 h-4 mr-1"
                            strokeWidth="2.5"
                        />
                        Log Out
                    </button>
                </div>
            </nav>
        </SidebarContainer>
    );
}

export default Sidebar;

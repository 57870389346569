import { useEffect, useState } from 'react';
import * as Avatar from '@radix-ui/react-avatar';
import * as Dialog from '@radix-ui/react-dialog';
import { useFetcher } from 'react-router-dom';
import { X } from 'react-feather';
import ActionAlert from '../../../base/design-system/ActionAlert';
import { ActionResponse } from '../../../../types';

function ChangeProfilePhotoModal({
    username,
    profilePhoto,
    children,
}: {
    username: string | null;
    profilePhoto: string | null;
    children: React.ReactNode;
}) {
    const fetcher = useFetcher();
    const [previewImage, setPreviewImage] = useState<string | null>(
        profilePhoto
    );
    const [response, setResponse] = useState<ActionResponse | null>(null);

    useEffect(() => {
        if (fetcher.state === 'idle' && fetcher.data) {
            setResponse(fetcher.data);
        }
    }, [fetcher.state, fetcher.data]);

    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        try {
            setResponse(null);
            const file = event.target.files[0];
            if (!file) {
                setPreviewImage(null);
                return;
            }

            // Validate file is an image
            if (!file.type.startsWith('image/')) {
                setResponse({
                    success: false,
                    message: 'Please upload an image file',
                });
                setPreviewImage(null);
                return;
            }

            // Validate file size
            if (file.size > 2 * 1024 * 1024) {
                setResponse({
                    success: false,
                    message: 'File size should be less than 2MB',
                });
                setPreviewImage(null);
                return;
            }

            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewImage(reader.result as string);
            };
            reader.readAsDataURL(file);
        } catch (error) {
            setResponse({ success: false, message: 'Error processing image' });
            console.error(error);
        }
    };

    function handleSubmit() {
        fetcher.submit(
            { intent: 'update_profile_photo', base64Image: previewImage },
            { method: 'post', encType: 'multipart/form-data' }
        );
    }

    return (
        <Dialog.Root>
            <Dialog.Trigger asChild>{children}</Dialog.Trigger>
            <Dialog.Portal>
                <Dialog.Overlay className="fixed inset-0 bg-black/60 animate-dialog-overlay-show" />
                <Dialog.Content className="w-[90vw] max-w-[450px] max-h-[85vh] fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 p-6 bg-white dark:bg-gray-700 rounded-md focus:outline-none animate-dialog-content-show">
                    <Dialog.Title asChild>
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            Change profile photo
                        </h3>
                    </Dialog.Title>
                    <div className="text-sm py-4 md:py-5">
                        <div className="space-y-4">
                            <div className="flex justify-center">
                                <Avatar.Root className="inline-flex size-40 select-none items-center justify-center overflow-hidden rounded-full bg-green-100 align-middle">
                                    <Avatar.Image
                                        className="size-full rounded-full object-cover"
                                        src={previewImage}
                                        alt="Profile picture preview"
                                    />
                                    <Avatar.Fallback
                                        className="flex size-full items-center justify-center bg-green-100 text-5xl leading-1 font-medium text-green-800"
                                        delayMs={600}
                                    >
                                        {username
                                            ? username.slice(0, 2).toUpperCase()
                                            : 'AN'}
                                    </Avatar.Fallback>
                                </Avatar.Root>
                            </div>

                            <div>
                                <label
                                    htmlFor="profile-photo-input"
                                    className="block mb-2 text-base font-medium text-gray-900 dark:text-white"
                                >
                                    Upload photo
                                </label>
                                <input
                                    type="file"
                                    id="profile-photo-input"
                                    name="photo"
                                    accept="image/*"
                                    onChange={handleImageUpload}
                                    className="block h-9 w-full rounded-lg border border-gray-300 bg-white text-sm italic text-gray-900 cursor-pointer file:cursor-pointer file:h-full file:border-solid file:border-gray-300 file:bg-transparent file:px-3 file:text-sm file:font-medium file:not-italic p-0 pe-3 file:me-3 file:border-0 file:border-e dark:text-gray-400 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                                />
                            </div>
                            <button
                                type="submit"
                                onClick={handleSubmit}
                                className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            >
                                Upload
                            </button>
                            <ActionAlert response={response} />
                        </div>
                    </div>
                    <Dialog.Close asChild>
                        <button
                            className="absolute top-2.5 right-2.5 h-6 w-6 inline-flex justify-center align-center rounded-full"
                            aria-label="Close"
                        >
                            <X className="inline w-4 h-4" strokeWidth="2" />
                        </button>
                    </Dialog.Close>
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    );
}

export default ChangeProfilePhotoModal;

import { redirect } from 'react-router-dom';

export async function action({ request }) {
    let data;
    let intent;

    const contentType = request.headers.get('Content-Type');

    if (contentType && contentType.includes('application/json')) {
        data = await request.json();
        intent = data.intent;
    } else {
        data = await request.formData();
        intent = data.get('intent');
    }

    let response: Response;

    if (intent === 'get_code') {
        const fields = JSON.parse(data.fields);
        const scenarios = JSON.parse(data.scenarios);
        response = await fetch(`/api/scenario-list-code`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ fields, scenarios }),
        });
    } else if (intent === 'get_code_from_file') {
        const file = data.get('file');

        // Client-side validation
        // If no file is uploaded, return an error
        if (typeof file === 'string') {
            return {
                success: false,
                intent: intent,
                message: 'No file uploaded.',
                code: '# No file uploaded.',
            };
        }

        const formData = new FormData();
        formData.append('file', file);
        response = await fetch(`/api/scenario-list-code-from-file`, {
            method: 'POST',
            body: formData,
        });
    } else if (intent === 'create_scenario_list') {
        const fields = JSON.parse(data.fields);
        const scenarios = JSON.parse(data.scenarios);
        response = await fetch(`/api/scenario-list-object`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ fields, scenarios }),
        });
    } else if (intent === 'create_scenario_list_from_file') {
        const file = data.get('file');
        const formData = new FormData();
        formData.append('file', file);
        response = await fetch(`/api/scenario-list-object-from-file`, {
            method: 'POST',
            body: formData,
        });
    }

    if (response.ok && intent === 'get_code') {
        const successData = await response.json();
        return {
            success: true,
            intent: intent,
            message: 'Successfully retrieved code!',
            code: successData.code,
            num_rows: successData.num_rows,
            fields: successData.fields,
            scenarios: successData.scenarios,
        };
    } else if (response.ok && intent === 'get_code_from_file') {
        const successData = await response.json();
        return {
            success: true,
            intent: intent,
            message: 'Successfully retrieved code!',
            code: successData.code,
            num_rows: successData.num_rows,
            fields: successData.fields,
            scenarios: successData.scenarios,
        };
    } else if (
        response.ok &&
        (intent === 'create_scenario_list' ||
            intent === 'create_scenario_list_from_file')
    ) {
        const successData = await response.json();
        const objectId = successData.uuid;
        return redirect(`/content/${objectId}`);
    } else if (response.status === 401) {
        return redirect('/login');
    } else if (response.status === 422) {
        const errorData = await response.json();
        return {
            success: false,
            intent: intent,
            message: 'Pydantic error.',
            code: '# Pydantic error.',
        };
    } else if (response.status === 504) {
        return {
            success: false,
            intent: intent,
            message: 'No response from server.',
            code: '# No response from server.',
        };
    } else {
        const errorData = await response.json();
        return {
            success: false,
            intent: intent,
            message: errorData.detail,
            code: `# ${errorData.detail}`,
        };
    }
}

export async function action({ request }) {
    const formData = await request.formData();

    const response = await fetch('/api/projects/create-from-url', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
            name: formData.get('name'),
            survey_url: formData.get('survey_url'),
        }),
    });

    if (response.ok) {
        const successData = await response.json();
        return { success: true, message: successData.message };
    } else if (response.status === 422) {
        return {
            success: false,
            message:
                'Invalid data. Please double-check to make sure you entered a valid url!',
        };
    } else if (response.status === 504) {
        return { success: false, message: 'No response from server.' };
    } else {
        const errorData = await response.json();
        return { success: false, message: errorData.detail };
    }
}

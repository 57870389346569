import { Link } from 'react-router-dom';
import { ArrowRight, ExternalLink } from 'react-feather';

function GettingStartedIndex() {
    const linkStyles =
        'text-blue-500 hover:text-blue-600 hover:underline dark:text-primary-dark-text-accent dark:hover:text-primary-dark-text-accent';

    return (
        <div className="space-y-10">
            <div className="space-y-2">
                <h1 className="font-bold text-4xl pb-2">
                    Get started conducting research with AI.
                </h1>
                <p>
                    Expected Parrot tools simplify the process of simulating surveys,
                    conducting experiments, and managing a variety of research tasks with
                    AI agents and large language models. Features include:
                </p>
                <ul className="list-disc pl-5">
                    {/*<li>
                        Choose whether to conduct research programmatically with our
                        open-source Python library (EDSL), or interactively at our no-code web app.
                    </li>*/}
                    <li>
                        An intuitive open-source library designed for conducting research.
                    </li>
                    <li>
                        Access to many popular language models with a single API key.
                    </li>
                    <li>
                        An integrated platform for storing and sharing survey results and projects.
                    </li>
                    <li>
                        Extensive documentation with tutorials and examples for many use cases.
                    </li>
                </ul>
            </div>
            <div className="space-y-2">
                <h2 className="font-bold text-3xl pb-2">Coop</h2>
                <p>
                    Our free platform for AI-based research. 
                    Use it to store and share projects created with EDSL or 
                    Survey Builder, and explore other research.
                    Coop also provides an Expected Parrot API key that lets you access many popular 
                    language models at once and run surveys remotely at the Expected Parrot server. 
                </p>
                <p> 
                    →{' '}
                    <Link
                        to="/login"
                        className={linkStyles}
                    >
                        Create an account
                    </Link>{' '}
                    to get started. Learn more about{' '} 
                    <Link
                        to="/getting-started/coop-how-it-works"
                        className={linkStyles}
                    >
                        how it works
                    </Link>.                    
                </p>
                <br></br>
                <h2 className="font-bold text-3xl pb-2">EDSL</h2>
                <p>
                    EDSL (
                    <span className="italic">
                        Expected Parrot Domain-Specific Language
                    </span>
                    ){' '}
                    is our open-source Python package for designing and conducting surveys with AI 
                    agents and large language models.
                    It simplifies the process of constructing questions, combining them into 
                    surveys with logic and rules, designing personas to answer the questions, and 
                    selecting language models to generate the responses.
                    It comes with built-in methods for analyzing results as datasets, extending
                    existing surveys with new data, and integrating data from many sources.
                </p>
                <p> 
                    →{' '}
                    See instructions on{' '}
                    <Link
                        to="/getting-started/edsl-installation"
                        className={linkStyles}
                    >
                        installing EDSL
                    </Link>{' '}
                    and{' '}
                    <Link
                        to="/getting-started/edsl-api-keys"
                        className={linkStyles}
                    >
                        storing API keys
                    </Link>{' '}
                    for language models. Then try running examples in our{' '}
                    <a
                        href="https://docs.expectedparrot.com/en/latest/starter_tutorial.html"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`inline-flex items-center ${linkStyles}`}
                    >
                        starter tutorial
                    </a>.
                </p>
                <br></br>
                {/* 
                <p>
                    <b>Survey Builder</b> is our no-code interface for building and launching surveys 
                    with humans and/or AI agent respondents. 
                    Choose from ready-to-use agent sets or design your own agents to answer your questions, 
                    and easily distribute web-based surveys to your own audiences or gather responses 
                    with our Prolific integration. 
                    Hybrid responses are readily available for comparison, analysis, and exporting to other 
                    tools.
                </p>
                <p> 
                    →{' '}
                    Log into your Coop account to{' '}
                    <Link
                        to="/getting-started/survey-builder"
                        className={linkStyles}
                    >
                        run an example survey
                    </Link>
                    {' '}or{' '}
                    <Link
                        to="/getting-started/survey-builder"
                        className={linkStyles}
                    >
                        create a new one
                    </Link>
                    .
                </p>
                <br></br>
                */}
            </div>
            {/*
            <div className="space-y-2">
                <h3 className="font-bold text-2xl">
                    1. Install the EDSL package.
                </h3>
                <p>
                    See{' '}
                    <Link
                        to="/getting-started/edsl-installation"
                        className={linkStyles}
                    >
                        installation
                    </Link>{' '}
                    instructions.
                </p>
            </div>
            <div className="space-y-2">
                <h3 className="font-bold text-2xl">
                    2. Store API keys for language models.
                </h3>
                <p>
                    Get an Expected Parrot key to run surveys with any available
                    models at the Expected Parrot server, or store your own
                    keys. See{' '}
                    <Link
                        to="/getting-started/edsl-api-keys"
                        className={linkStyles}
                    >
                        how to store API keys
                    </Link>
                    .
                </p>
            </div>
            <div className="space-y-2">
                <h3 className="font-bold text-2xl">
                    3. Create and run a survey.
                </h3>
                <p>
                    Explore a{' '}
                    <a
                        href="https://docs.expectedparrot.com/en/latest/starter_tutorial.html"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`inline-flex items-center ${linkStyles}`}
                    >
                        starter tutorial
                    </a>{' '}
                    and other{' '}
                    <a
                        href="https://docs.expectedparrot.com"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`inline-flex items-center ${linkStyles}`}
                    >
                        examples and templates
                    </a>{' '}
                    for many different use cases.
                </p>
            </div>
            <div className="space-y-2">
                <h3 className="font-bold text-2xl">4. Collaborate on Coop.</h3>
                <p>
                    A platform for creating, storing, and sharing AI-based
                    research.{' '}
                    <Link to="/login" className={linkStyles}>
                        Log in or sign up
                        <ArrowRight
                            className="inline w-4 h-4 ml-1"
                            strokeWidth="1.5"
                        />
                    </Link>
                </p>
            </div>
            */}
        </div>
    );
}

export default GettingStartedIndex;

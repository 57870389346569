import { useEffect } from 'react';
import { useFetcher } from 'react-router-dom';
import { JobState, ModelListType, ModelListPreview } from './types';
import ActionAlert from '../../../../../../base/design-system/ActionAlert';

interface ModelListPreviewProps {
    preview: ModelListPreview;
    models: { inference_service: string; model_name: string }[];
}

function ModelListPreview({ preview, models }: ModelListPreviewProps) {
    return (
        <div className="space-y-4 mt-6 p-4 bg-gray-50 dark:bg-gray-700/30 rounded-lg border border-gray-200 dark:border-gray-600">
            <div>
                <p className="font-medium text-gray-900 dark:text-gray-100">
                    {preview.description ||
                        'This model list has no description.'}
                </p>
                <p className="text-sm text-blue-600 dark:text-blue-400">
                    {preview.numModels}{' '}
                    {preview.numModels === 1 ? 'model' : 'models'}
                </p>
            </div>
            <div className="text-sm">
                <p className="font-medium text-gray-900 dark:text-gray-100">
                    Models:
                </p>
                <ul>
                    {models.map((model, index) => (
                        <li
                            className="ml-2 list-inside list-decimal"
                            key={index}
                        >
                            {model.inference_service}: {model.model_name}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

interface AddModelsFormProps {
    jobState: JobState;
    availableModels: {
        inference_service: string;
        models: string[];
    }[];
    handleModelSourceChange: (value: ModelListType) => void;
    handleModelUrlChange: (url: string) => void;
    handleSelectedModelsChange: (
        models: { inference_service: string; model_name: string }[]
    ) => void;
    handleURLModelListPreviewChange: (
        info:
            | (ModelListPreview & {
                  uuid: string;
                  models: { inference_service: string; model_name: string }[];
              })
            | null
    ) => void;
}

function AddModelsForm({
    jobState,
    availableModels,
    handleModelSourceChange,
    handleModelUrlChange,
    handleSelectedModelsChange,
    handleURLModelListPreviewChange,
}: AddModelsFormProps) {
    const fetcher = useFetcher();

    useEffect(() => {
        if (fetcher.data && fetcher.data.success) {
            handleURLModelListPreviewChange({
                numModels: fetcher.data.num_models,
                description: fetcher.data.description,
                uuid: fetcher.data.uuid,
                models: fetcher.data.models,
            });
        }
    }, [fetcher, fetcher.data]);

    const handleModelCheckboxChange = (
        inferenceService: string,
        modelName: string
    ) => {
        if (jobState.modelList.type !== 'selected') return;

        const currentModels = jobState.modelList.models;
        const modelExists = currentModels.some(
            (m) =>
                m.inference_service === inferenceService &&
                m.model_name === modelName
        );

        if (modelExists) {
            // Remove the model if it exists
            handleSelectedModelsChange(
                currentModels.filter(
                    (m) =>
                        !(
                            m.inference_service === inferenceService &&
                            m.model_name === modelName
                        )
                )
            );
        } else {
            // Add the model if it doesn't exist
            handleSelectedModelsChange([
                ...currentModels,
                { inference_service: inferenceService, model_name: modelName },
            ]);
        }
    };

    const isModelSelected = (inferenceService: string, modelName: string) => {
        if (jobState.modelList.type !== 'selected') return false;
        return jobState.modelList.models.some(
            (m) =>
                m.inference_service === inferenceService &&
                m.model_name === modelName
        );
    };

    return (
        <div className="space-y-6">
            <div className="space-y-2">
                <p className="font-medium mb-4">
                    How would you like to add models?
                </p>
                <div className="flex items-center">
                    <input
                        type="radio"
                        id="selected-models"
                        name="model-source"
                        value="selected"
                        checked={jobState.modelList.type === 'selected'}
                        onChange={() => handleModelSourceChange('selected')}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                    />
                    <label
                        htmlFor="selected-models"
                        className="ml-2 text-sm text-gray-900 dark:text-gray-300"
                    >
                        Select from available models
                    </label>
                </div>
                <div className="flex items-center">
                    <input
                        type="radio"
                        id="url-models"
                        name="model-source"
                        value="url"
                        checked={jobState.modelList.type === 'url'}
                        onChange={() => handleModelSourceChange('url')}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                    />
                    <label
                        htmlFor="url-models"
                        className="ml-2 text-sm text-gray-900 dark:text-gray-300"
                    >
                        Add by URL
                    </label>
                </div>
            </div>

            {jobState.modelList.type === 'selected' && (
                <div className="space-y-4">
                    {availableModels.map((service) => (
                        <div
                            key={service.inference_service}
                            className="space-y-2"
                        >
                            <p className="font-medium text-gray-900 dark:text-gray-100">
                                {service.inference_service}
                            </p>
                            <div className="ml-4 space-y-2">
                                {service.models.map((modelName) => (
                                    <div
                                        key={modelName}
                                        className="flex items-center"
                                    >
                                        <input
                                            type="checkbox"
                                            id={`${service.inference_service}-${modelName}`}
                                            checked={isModelSelected(
                                                service.inference_service,
                                                modelName
                                            )}
                                            onChange={() =>
                                                handleModelCheckboxChange(
                                                    service.inference_service,
                                                    modelName
                                                )
                                            }
                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                                        />
                                        <label
                                            htmlFor={`${service.inference_service}-${modelName}`}
                                            className="ml-2 text-sm"
                                        >
                                            {modelName}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            )}

            {jobState.modelList.type === 'url' && (
                <div className="space-y-2">
                    <p className="block font-medium text-gray-900 dark:text-gray-100 mt-4 mb-2">
                        URL
                    </p>
                    <fetcher.Form
                        method="post"
                        className="flex items-center gap-2"
                    >
                        <input
                            name="model_list_url"
                            value={jobState.modelList.url}
                            onChange={(event) =>
                                handleModelUrlChange(event.target.value)
                            }
                            placeholder="URL for model or model list"
                            className="flex-grow p-2 text-sm bg-gray-50 focus:outline-none border rounded-lg border-gray-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700/20 dark:border-gray-500 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:placeholder-gray-400"
                        />
                        <button
                            name="intent"
                            value="find_model_list"
                            className="px-4 py-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        >
                            Find
                        </button>
                    </fetcher.Form>
                    <div className="text-sm">
                        {fetcher.data && !fetcher.data.success && (
                            <ActionAlert response={fetcher.data} />
                        )}
                    </div>
                </div>
            )}
            {jobState.modelList.type === 'url' && (
                <ModelListPreview
                    preview={jobState.modelList.preview}
                    models={
                        jobState.modelList.type === 'url'
                            ? jobState.modelList.models
                            : []
                    }
                />
            )}
        </div>
    );
}

export default AddModelsForm;

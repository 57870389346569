import { useState, useRef } from 'react';
import { useActionData, useFetcher, useOutletContext } from 'react-router-dom';
import { Box, Trash2, Plus, Upload } from 'react-feather';
import * as Tabs from '@radix-ui/react-tabs';
import {
    SandpackProvider,
    SandpackLayout,
    SandpackCodeViewer,
} from '@codesandbox/sandpack-react';
import { python } from '@codemirror/lang-python';
import { CreateScenarioListModal } from './PublishScenarioListModals';
import { ActionResponse } from '../../../../types';
import * as VisuallyHidden from '@radix-ui/react-visually-hidden';
import { Field, Scenario } from './types';

export interface ScenarioListState {
    method: 'manual' | 'file';
    fields: Field[];
    scenarios: Scenario[];
    scenarioFile: File | null;
    hasDataChanged: boolean;
}

interface ScenarioPreviewProps {
    previewData?: {
        fields: string[];
        scenarios: Array<Record<string, string>>;
    } | null;
    totalScenarios?: number;
}

function ScenarioPreview({
    previewData,
    totalScenarios,
}: ScenarioPreviewProps) {
    if (!previewData) {
        return null;
    }

    const displayFields = previewData.fields.map((name) => ({
        id: name,
        name,
    }));
    const displayScenarios = previewData.scenarios.map((scenario) => ({
        id: crypto.randomUUID(),
        fields: scenario,
    }));

    return (
        <div className="space-y-4">
            <div>
                <div className="text-lg font-medium text-gray-900 dark:text-gray-100">
                    Total scenarios: {totalScenarios?.toLocaleString() ?? '0'}
                </div>
                <div className="text-sm text-gray-500 dark:text-gray-400">
                    (Preview is limited to the first 100 scenarios)
                </div>
            </div>
            <div className="overflow-x-auto">
                <table className="w-full text-sm text-left text-gray-900 dark:text-gray-400">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                        <tr>
                            <th scope="col" className="px-6 py-3">
                                Scenario
                            </th>
                            {displayFields.map((field) => (
                                <th
                                    key={field.id}
                                    scope="col"
                                    className="px-6 py-3"
                                >
                                    {field.name}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {displayScenarios.map((scenario, index) => (
                            <tr
                                key={scenario.id}
                                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                            >
                                <th
                                    scope="row"
                                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                                >
                                    Scenario #{index + 1}
                                </th>
                                {displayFields.map((field) => (
                                    <td key={field.id} className="px-6 py-4">
                                        {scenario.fields[field.name]}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

function ConfigureScenariosForm({
    scenarioList,
    addScenario,
    removeScenario,
    updateScenarioValue,
}: {
    scenarioList: ScenarioListState;
    addScenario: () => void;
    removeScenario: (id: string) => void;
    updateScenarioValue: (id: string, fieldId: string, value: string) => void;
}) {
    return (
        <div className="space-y-4">
            {scenarioList.scenarios.map((scenario, index) => (
                <div
                    key={scenario.id}
                    className="p-4 border rounded-lg dark:border-gray-600"
                >
                    <div className="flex justify-between items-center mb-2">
                        <h4 className="font-medium">Scenario #{index + 1}</h4>
                        <button
                            onClick={() => removeScenario(scenario.id)}
                            className="p-1 text-red-600 hover:text-red-800 dark:text-red-400 dark:hover:text-red-300"
                            aria-label="Delete scenario"
                        >
                            <Trash2 className="w-4 h-4" />
                        </button>
                    </div>
                    {Object.entries(scenario.fields).map(([fieldId, value]) => {
                        const field = scenarioList.fields.find(
                            (f) => f.id === fieldId
                        );
                        return (
                            <div key={fieldId} className="mb-2">
                                <label
                                    htmlFor={`${scenario.id}-${field.id}`}
                                    className="block text-sm font-medium mb-1"
                                >
                                    {field.name || 'unnamed field'}
                                </label>
                                <input
                                    id={`${scenario.id}-${field.id}`}
                                    type="text"
                                    value={value}
                                    onChange={(e) =>
                                        updateScenarioValue(
                                            scenario.id,
                                            fieldId,
                                            e.target.value
                                        )
                                    }
                                    className="w-full p-2 text-sm bg-gray-50 focus:outline-none border rounded-lg border-gray-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700/20 dark:border-gray-500 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:placeholder-gray-400"
                                />
                            </div>
                        );
                    })}
                </div>
            ))}
            <div className="flex items-center space-x-2">
                <button
                    onClick={addScenario}
                    className="flex items-center px-4 py-2.5 hover:bg-gray-300/20 hover:transition-colors border border-gray-300 dark:border-2 dark:border-gray-500 rounded-md font-medium text-sm"
                >
                    <Plus className="w-4 h-4 mr-1" />
                    Add new scenario
                </button>
            </div>
        </div>
    );
}

interface DataTabProps {
    scenarioList: ScenarioListState;
    addField: () => void;
    removeField: (id: string) => void;
    updateField: (id: string, name: string) => void;
    addScenario: () => void;
    removeScenario: (id: string) => void;
    updateScenarioValue: (id: string, fieldId: string, value: string) => void;
    handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onMethodChange: (method: 'manual' | 'file') => void;
}

function DataTab({
    scenarioList,
    addField,
    removeField,
    updateField,
    addScenario,
    removeScenario,
    updateScenarioValue,
    handleFileChange,
    onMethodChange,
}: DataTabProps) {
    return (
        <Tabs.Root
            defaultValue={scenarioList.method}
            onValueChange={(value) => {
                if (value === 'manual' || value === 'file') {
                    onMethodChange(value);
                }
            }}
        >
            <Tabs.List
                className="inline-flex p-1 space-x-1 bg-gray-100 rounded-lg dark:bg-gray-700/50 mb-4"
                aria-label="Switch tabs"
            >
                <Tabs.Trigger
                    className="px-3 py-2 text-sm font-medium rounded-md text-center transition-colors data-[state=active]:bg-white data-[state=active]:text-blue-600 data-[state=inactive]:hover:bg-gray-50 dark:data-[state=active]:bg-gray-600 dark:data-[state=active]:text-primary-dark-text-accent dark:data-[state=inactive]:hover:bg-gray-600/50"
                    value="manual"
                >
                    Enter manually
                </Tabs.Trigger>
                <Tabs.Trigger
                    className="px-3 py-2 text-sm font-medium rounded-md text-center transition-colors data-[state=active]:bg-white data-[state=active]:text-blue-600 data-[state=inactive]:hover:bg-gray-50 dark:data-[state=active]:bg-gray-600 dark:data-[state=active]:text-primary-dark-text-accent dark:data-[state=inactive]:hover:bg-gray-600/50"
                    value="file"
                >
                    Upload from file
                </Tabs.Trigger>
            </Tabs.List>

            <Tabs.Content value="manual">
                <div className="space-y-6">
                    {/* Fields section */}
                    <div>
                        <h3 className="text-lg font-medium mb-4">
                            Define fields
                        </h3>
                        <div className="space-y-2">
                            {scenarioList.fields.map((field) => (
                                <div
                                    key={field.id}
                                    className="flex items-center"
                                >
                                    <VisuallyHidden.Root>
                                        <label htmlFor={`${field.id}-name`}>
                                            Field name
                                        </label>
                                    </VisuallyHidden.Root>
                                    <input
                                        id={`${field.id}-name`}
                                        type="text"
                                        value={field.name}
                                        onChange={(e) =>
                                            updateField(
                                                field.id,
                                                e.target.value
                                            )
                                        }
                                        className="flex-grow p-2 text-sm bg-gray-50 focus:outline-none border rounded-lg border-gray-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700/20 dark:border-gray-500 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:placeholder-gray-400"
                                    />
                                    <button
                                        onClick={() => removeField(field.id)}
                                        className="p-2 text-red-600 hover:text-red-800 dark:text-red-400 dark:hover:text-red-300"
                                        aria-label="Delete field"
                                    >
                                        <Trash2 className="w-4 h-4" />
                                    </button>
                                </div>
                            ))}
                            <button
                                onClick={addField}
                                className="flex items-center px-4 py-2.5 hover:bg-gray-300/20 hover:transition-colors border border-gray-300 dark:border-2 dark:border-gray-500 rounded-md font-medium text-sm"
                            >
                                <Plus className="w-4 h-4 mr-1" />
                                Add new field
                            </button>
                        </div>
                    </div>

                    {/* Scenarios section */}
                    <div>
                        <h3 className="text-lg font-medium mb-4">
                            Configure scenarios
                        </h3>
                        <ConfigureScenariosForm
                            scenarioList={scenarioList}
                            addScenario={addScenario}
                            removeScenario={removeScenario}
                            updateScenarioValue={updateScenarioValue}
                        />
                    </div>
                </div>
            </Tabs.Content>

            <Tabs.Content value="file">
                <FileUpload
                    onFileChange={handleFileChange}
                    selectedFile={scenarioList.scenarioFile}
                />
            </Tabs.Content>
        </Tabs.Root>
    );
}

function PreviewTab({
    isLoading,
    scenarioListData,
}: {
    isLoading: boolean;
    scenarioListData: {
        success?: boolean;
        fields: string[];
        scenarios: Record<string, string>[];
        num_rows: number;
    } | null;
}) {
    if (isLoading) {
        return 'Loading preview...';
    }

    if (!scenarioListData?.success) {
        return 'There was an error processing the scenario list. See the Code tab for more details.';
    }

    return (
        <ScenarioPreview
            previewData={{
                fields: scenarioListData.fields,
                scenarios: scenarioListData.scenarios,
            }}
            totalScenarios={scenarioListData.num_rows}
        />
    );
}

interface FileUploadProps {
    onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    selectedFile: File | null;
}

function FileUpload({ onFileChange, selectedFile }: FileUploadProps) {
    const fileInputRef = useRef<HTMLInputElement>(null);

    return (
        <div>
            <input
                ref={fileInputRef}
                id="scenario-file"
                name="file"
                type="file"
                accept=".csv"
                onChange={onFileChange}
                className="hidden"
            />
            <div className="space-y-2">
                <button
                    onClick={() => fileInputRef.current?.click()}
                    className="flex items-center px-4 py-2.5 hover:bg-gray-300/20 hover:transition-colors border border-gray-300 dark:border-2 dark:border-gray-500 rounded-md font-medium text-sm"
                >
                    <Upload className="w-4 h-4 mr-1" />
                    Upload CSV file
                </button>
                <p className="text-sm text-gray-600 dark:text-gray-400">
                    Maximum file size: 250MB
                </p>
            </div>
            {selectedFile && (
                <div className="mt-2 p-3 bg-gray-50 dark:bg-gray-700/20 rounded-lg">
                    <p className="text-sm">
                        Selected file:{' '}
                        <span className="font-medium">{selectedFile.name}</span>
                    </p>
                </div>
            )}
        </div>
    );
}

function ScenarioList() {
    const isDarkMode: boolean = useOutletContext();
    const fetcher = useFetcher();
    const actionData = useActionData() as ActionResponse & {
        intent: string;
        message: string;
        code: string;
        num_rows: number;
        fields: string[];
        scenarios: Record<string, string>[];
    };

    const [scenarioList, setScenarioList] = useState<ScenarioListState>(() => {
        const fields = [
            { id: crypto.randomUUID(), name: 'location' },
            { id: crypto.randomUUID(), name: 'time' },
            { id: crypto.randomUUID(), name: 'weather' },
        ];

        const scenarios = [
            {
                id: crypto.randomUUID(),
                fields: {
                    [fields[0].id]: 'park',
                    [fields[1].id]: 'morning',
                    [fields[2].id]: 'sunny',
                },
            },
            {
                id: crypto.randomUUID(),
                fields: {
                    [fields[0].id]: 'beach',
                    [fields[1].id]: 'afternoon',
                    [fields[2].id]: 'cloudy',
                },
            },
        ];

        return {
            method: 'manual',
            fields: fields,
            scenarios: scenarios,
            scenarioFile: null,
            hasDataChanged: true,
        };
    });

    // ... implement all the handler functions similar to AgentList but with updated names ...
    const addField = () => {
        const newField = { id: crypto.randomUUID(), name: '' };
        setScenarioList((prevState) => ({
            ...prevState,
            hasDataChanged: true,
            fields: [...prevState.fields, newField],
            scenarios: prevState.scenarios.map((scenario) => ({
                ...scenario,
                fields: { ...scenario.fields, [newField.id]: '' },
            })),
        }));
    };

    const removeField = (id: string) => {
        setScenarioList((prevState) => ({
            ...prevState,
            hasDataChanged: true,
            fields: prevState.fields.filter((field) => field.id !== id),
            scenarios: prevState.scenarios.map((scenario) => ({
                ...scenario,
                fields: Object.fromEntries(
                    Object.entries(scenario.fields).filter(
                        ([fieldId]) => fieldId !== id
                    )
                ),
            })),
        }));
    };

    const updateField = (id: string, name: string) => {
        setScenarioList((prevState) => ({
            ...prevState,
            hasDataChanged: true,
            fields: prevState.fields.map((field) =>
                field.id === id ? { ...field, name } : field
            ),
        }));
    };

    const addScenario = () => {
        const newScenario = {
            id: crypto.randomUUID(),
            fields: scenarioList.fields.reduce(
                (acc, field) => ({ ...acc, [field.id]: '' }),
                {}
            ),
        };
        setScenarioList((prevState) => ({
            ...prevState,
            hasDataChanged: true,
            scenarios: [...prevState.scenarios, newScenario],
        }));
    };

    const updateScenarioValue = (
        scenarioId: string,
        fieldId: string,
        value: string
    ) => {
        setScenarioList((prevState) => ({
            ...prevState,
            hasDataChanged: true,
            scenarios: prevState.scenarios.map((scenario) =>
                scenario.id === scenarioId
                    ? {
                          ...scenario,
                          fields: { ...scenario.fields, [fieldId]: value },
                      }
                    : scenario
            ),
        }));
    };

    const removeScenario = (id: string) => {
        setScenarioList((prevState) => ({
            ...prevState,
            hasDataChanged: true,
            scenarios: prevState.scenarios.filter(
                (scenario) => scenario.id !== id
            ),
        }));
    };

    function handleFileChange(event: React.ChangeEvent<HTMLInputElement>) {
        const file = event.target.files?.[0] || null;

        if (file) {
            const fileExtension = file.name.split('.').pop()?.toLowerCase();
            const isCSV = fileExtension === 'csv' && file.type === 'text/csv';

            if (!isCSV) {
                alert('Please upload a CSV file.');
                event.target.value = ''; // Clear the file input
                return;
            }

            // 250MB file size limit
            const fileSizeLimitMB = 250;

            if (file.size > fileSizeLimitMB * 1024 * 1024) {
                alert('File size exceeds 250MB limit.');
                event.target.value = ''; // Clear the file input
                return;
            }
        }

        setScenarioList((prevState) => ({
            ...prevState,
            hasDataChanged: true,
            method: 'file',
            scenarioFile: file,
        }));
    }

    const handleMethodChange = (method: 'manual' | 'file') => {
        setScenarioList((prevState) => ({
            ...prevState,
            hasDataChanged: true,
            method: method,
        }));
    };

    function handleGetCode(intent: string) {
        // Reset the change tracker
        setScenarioList((prevState) => ({
            ...prevState,
            hasDataChanged: false,
        }));
        const serializedFields = JSON.stringify(scenarioList.fields);
        const serializedScenarios = JSON.stringify(scenarioList.scenarios);
        fetcher.submit(
            {
                fields: serializedFields,
                scenarios: serializedScenarios,
                intent: intent,
            },
            { method: 'post', encType: 'application/json' }
        );
    }

    function handleGetCodeFromFile(intent: string) {
        // Reset the change tracker
        setScenarioList((prevState) => ({
            ...prevState,
            hasDataChanged: false,
        }));

        const formData = new FormData();
        formData.append('file', scenarioList.scenarioFile);
        formData.append('intent', intent);
        fetcher.submit(formData, {
            method: 'post',
            encType: 'multipart/form-data',
        });
    }

    const createScenarioListResponse =
        actionData !== undefined &&
        (actionData.intent === 'create_scenario_list' ||
            actionData.intent === 'create_scenario_list_from_file')
            ? actionData
            : null;

    const scenarioListData =
        fetcher.data !== undefined &&
        (fetcher.data.intent === 'get_code' ||
            fetcher.data.intent === 'get_code_from_file')
            ? fetcher.data
            : null;

    return (
        <Tabs.Root
            defaultValue="data"
            onValueChange={(newTab) => {
                if (
                    (newTab === 'code' || newTab === 'preview') &&
                    scenarioList.hasDataChanged
                ) {
                    // console.log('Data changed. Re-requesting code...');
                    if (scenarioList.method === 'manual') {
                        handleGetCode('get_code');
                    } else {
                        handleGetCodeFromFile('get_code_from_file');
                    }
                } else if (newTab === 'code' || newTab === 'preview') {
                    // console.log('Data unchanged. Not re-requesting code.');
                }
            }}
        >
            <div className="flex flex-col lg:flex-row justify-between">
                <Tabs.List
                    className="flex gap-x-2 mb-4"
                    aria-label="Switch tabs"
                >
                    <Tabs.Trigger
                        className="px-4 pb-4 border-b-2 rounded-t-lg text-sm font-medium text-center data-[state=active]:text-blue-600 data-[state=active]:border-blue-600 data-[state=inactive]:border-gray-200 dark:data-[state=active]:text-primary-dark-text-accent dark:data-[state=active]:border-primary-dark-text-accent"
                        value="data"
                    >
                        Data
                    </Tabs.Trigger>
                    <Tabs.Trigger
                        className="px-4 pb-4 border-b-2 rounded-t-lg text-sm font-medium text-center data-[state=active]:text-blue-600 data-[state=active]:border-blue-600 data-[state=inactive]:border-gray-200 dark:data-[state=active]:text-primary-dark-text-accent dark:data-[state=active]:border-primary-dark-text-accent"
                        value="code"
                    >
                        <div className="flex">
                            Code
                            {scenarioListData !== null &&
                                !scenarioListData.success && (
                                    <div className="h-2 w-2 ml-1 rounded-full bg-red-400" />
                                )}
                        </div>
                    </Tabs.Trigger>
                    <Tabs.Trigger
                        className="px-4 pb-4 border-b-2 rounded-t-lg text-sm font-medium text-center data-[state=active]:text-blue-600 data-[state=active]:border-blue-600 data-[state=inactive]:border-gray-200 dark:data-[state=active]:text-primary-dark-text-accent dark:data-[state=active]:border-primary-dark-text-accent"
                        value="preview"
                    >
                        Preview
                    </Tabs.Trigger>
                </Tabs.List>
                <div className="flex flex-row gap-4 mb-4">
                    <CreateScenarioListModal
                        fields={scenarioList.fields}
                        scenarios={scenarioList.scenarios}
                        createScenarioListResponse={createScenarioListResponse}
                        method={scenarioList.method}
                        scenarioFile={scenarioList.scenarioFile}
                    >
                        <button className="flex items-center gap-2 px-4 py-2.5 bg-green-600 hover:bg-green-700 hover:transition-colors rounded-md text-white font-medium text-sm">
                            <Box className="w-4" />
                            Create scenario list
                        </button>
                    </CreateScenarioListModal>
                </div>
            </div>
            <Tabs.Content value="data">
                <DataTab
                    scenarioList={scenarioList}
                    addField={addField}
                    removeField={removeField}
                    updateField={updateField}
                    addScenario={addScenario}
                    removeScenario={removeScenario}
                    updateScenarioValue={updateScenarioValue}
                    handleFileChange={handleFileChange}
                    onMethodChange={handleMethodChange}
                />
            </Tabs.Content>
            <Tabs.Content value="code">
                <SandpackProvider
                    options={{ classes: { 'sp-stack': '!h-fit' } }}
                    files={{
                        'survey_code.py': {
                            code:
                                scenarioListData !== null
                                    ? scenarioListData.code
                                    : '# Nothing to see here yet!',
                            active: true,
                        },
                    }}
                    theme={isDarkMode ? 'dark' : 'light'}
                >
                    <SandpackLayout>
                        <SandpackCodeViewer
                            showLineNumbers={true}
                            showTabs={false}
                            additionalLanguages={[
                                {
                                    name: 'python',
                                    extensions: ['py'],
                                    language: python(),
                                },
                            ]}
                        />
                    </SandpackLayout>
                </SandpackProvider>
            </Tabs.Content>
            <Tabs.Content value="preview">
                <PreviewTab
                    isLoading={fetcher.state !== 'idle'}
                    scenarioListData={scenarioListData}
                />
            </Tabs.Content>
        </Tabs.Root>
    );
}

export default ScenarioList;

import { Fragment, useState } from 'react';
import {
    useFetcher,
    useLoaderData,
    useOutletContext,
    Link,
} from 'react-router-dom';
import * as Popover from '@radix-ui/react-popover';
import { ArrowLeft, Trash2 } from 'react-feather';
import {
    SandpackProvider,
    SandpackLayout,
    SandpackCodeViewer,
} from '@codesandbox/sandpack-react';
import { python } from '@codemirror/lang-python';
import Pagination from '../../../../../base/Pagination';
import { CopyCodeBtn } from '../../../../../Content/routes/ContentId/ActionRowButtons';
import { formatDate } from '../../../../../../utils';

interface RemoteCacheEntry {
    id: string;
    created_ts: number;
    model: string;
    description: string;
    raw_code: string;
}

interface RemoteCacheEntriesData {
    cache_entries: RemoteCacheEntry[];
    cache_entry_count: number;
    search_results_count: number;
    current_page: number;
    page_size: number;
    total_pages: number;
    search_query: string | null;
}

function DeleteEntryBtn({ entryId }: { entryId: string }) {
    const fetcher = useFetcher();

    return (
        <fetcher.Form method="post">
            <input type="hidden" name="entry_uuid" value={entryId} />
            <button
                type="submit"
                name="intent"
                value="delete_entry"
                className="bg-red-600 hover:bg-red-700 border-2 border-red-600 hover:border-red-700 rounded-md px-3 py-2 text-white font-semibold text-sm"
                onClick={(event) => event.stopPropagation()}
            >
                Delete
            </button>
        </fetcher.Form>
    );
}

function Entries() {
    const data = useLoaderData() as RemoteCacheEntriesData;
    const isDarkMode: boolean = useOutletContext();
    const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set());
    const [selectedEntries, setSelectedEntries] = useState<Set<string>>(
        new Set()
    );

    const startIndex = (data.current_page - 1) * data.page_size + 1;
    const endIndex = Math.min(
        startIndex + data.page_size - 1,
        data.search_results_count
    );

    function handleSelectAll(event: React.ChangeEvent<HTMLInputElement>) {
        if (event.target.checked) {
            setSelectedEntries(
                new Set(data.cache_entries.map((entry) => entry.id))
            );
        } else {
            setSelectedEntries(new Set());
        }
    }

    function handleSelectEntry(id: string) {
        setSelectedEntries((prev) => {
            const newSet = new Set(prev);
            if (newSet.has(id)) {
                newSet.delete(id);
            } else {
                newSet.add(id);
            }
            return newSet;
        });
    }

    function toggleRow(id: string) {
        setExpandedRows((prev) => {
            const newSet = new Set(prev);
            if (newSet.has(id)) {
                newSet.delete(id);
            } else {
                newSet.add(id);
            }
            return newSet;
        });
    }

    const btnBaseStyles =
        'py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white border-gray-200 hover:bg-gray-100 focus:z-10 focus:ring focus:ring-gray-100 dark:focus:ring-gray-500 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:bg-gray-700';

    return (
        <>
            <div className="space-y-2">
                <h1 className="font-bold text-3xl pb-2">Remote Cache</h1>
                <Link
                    to="/home/remote-cache"
                    className="flex items-center text-lg pb-2 text-blue-500 hover:text-blue-600 dark:text-primary-dark-text-accent dark:hover:text-primary-dark-text-accent"
                >
                    <ArrowLeft
                        className="inline w-4 h-4 ml-1"
                        strokeWidth="2"
                    />
                    <span className="ml-2">Back to remote cache logs</span>
                </Link>
            </div>
            <div className="space-y-4 mt-8">
                {/* Bulk actions and search bar would go here */}
                <div className="flex justify-between mt-4">
                    {data.cache_entry_count === 0 ? (
                        <p>Showing 0 entries</p>
                    ) : data.search_results_count === 0 ? (
                        <p>Showing 0 results for "{data.search_query}"</p>
                    ) : data.search_query ? (
                        <p>
                            Showing {startIndex}-{endIndex} of{' '}
                            {data.search_results_count} results for "
                            {data.search_query}"
                        </p>
                    ) : (
                        <p>
                            Showing {startIndex}-{endIndex} of{' '}
                            {data.search_results_count} entries
                        </p>
                    )}
                    {/* <p>
                        ({selectedEntries.size} of {data.cache_entries.length}{' '}
                        selected)
                    </p> */}
                    <Pagination
                        style="grayscale"
                        currentPage={data.current_page}
                        totalPages={data.total_pages}
                    />
                </div>
                <table className="w-full text-sm table-fixed relative">
                    {/* Todo: Add sticky top-0 to make the header sticky */}
                    <thead className="bg-white dark:bg-primary-dark-bg border-b dark:border-gray-100/20">
                        <tr>
                            {/* <th className="w-1/6 p-0">
                                <label className="block w-full h-full px-4 py-2 text-left">
                                    <input
                                        type="checkbox"
                                        onChange={handleSelectAll}
                                        checked={
                                            selectedEntries.size ===
                                            data.cache_entries.length
                                        }
                                        className="form-checkbox h-5 w-5 text-blue-600 dark:text-primary-dark-text-accent transition duration-150 ease-in-out"
                                    />
                                </label>
                            </th> */}
                            <th className="w-2/6 py-2 px-4 text-left font-semibold">
                                Date
                            </th>
                            <th className="w-1/6 py-2 px-4 text-left font-semibold">
                                Model
                            </th>
                            <th className="w-2/6 py-2 px-4 text-left font-semibold">
                                Description
                            </th>
                            <th className="w-1/6 py-2 px-4 text-center font-semibold">
                                Delete
                            </th>
                        </tr>
                    </thead>

                    <tbody className="divide-y divide-gray-200 dark:divide-gray-600">
                        {data.cache_entries.map((entry, index) => (
                            <Fragment key={entry.id}>
                                <tr
                                    className={`cursor-pointer ${
                                        index % 2 === 0
                                            ? 'bg-white dark:bg-gray-800'
                                            : 'bg-gray-50 dark:bg-gray-700'
                                    }`}
                                    onClick={() => toggleRow(entry.id)}
                                >
                                    {/* <td className="p-0">
                                        <label className="block w-full h-full px-4 py-2">
                                            <input
                                                type="checkbox"
                                                className="form-checkbox h-5 w-5 text-blue-600 dark:text-primary-dark-text-accent transition duration-150 ease-in-out"
                                                checked={selectedEntries.has(
                                                    entry.id
                                                )}
                                                onChange={() =>
                                                    handleSelectEntry(entry.id)
                                                }
                                            />
                                        </label>
                                    </td> */}
                                    <td className="px-4 py-2 text-sm text-gray-700 dark:text-gray-300">
                                        {formatDate(
                                            entry.created_ts,
                                            'MMMM D, YYYY'
                                        )}
                                    </td>
                                    <td className="px-4 py-2 text-sm text-gray-700 dark:text-gray-300">
                                        {entry.model}
                                    </td>
                                    <td
                                        className="px-4 py-2 text-sm text-gray-700 dark:text-gray-300"
                                        style={{
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap',
                                        }}
                                    >
                                        {entry.description}
                                    </td>
                                    <td className="relative text-center">
                                        <Popover.Root>
                                            <Popover.Trigger asChild>
                                                <button
                                                    onClick={(event) =>
                                                        event.stopPropagation()
                                                    }
                                                >
                                                    <Trash2
                                                        className="inline w-4 h-4 ml-1 hover:text-red-700"
                                                        strokeWidth="2"
                                                    />
                                                </button>
                                            </Popover.Trigger>
                                            <Popover.Portal>
                                                <Popover.Content
                                                    className="w-48 bg-white dark:bg-gray-700 rounded-lg shadow z-20"
                                                    sideOffset={5}
                                                >
                                                    <div className="flex flex-col gap-y-4 p-4 text-center">
                                                        Permanently delete this
                                                        entry?
                                                        <div className="flex justify-center gap-x-2">
                                                            <Popover.Close
                                                                asChild
                                                            >
                                                                <button
                                                                    onClick={(
                                                                        event
                                                                    ) =>
                                                                        event.stopPropagation()
                                                                    }
                                                                    className="hover:bg-gray-300/20 border border-gray-300 dark:border-2 dark:border-gray-500 font-semibold text-sm px-3 py-2 rounded-md"
                                                                >
                                                                    Cancel
                                                                </button>
                                                            </Popover.Close>
                                                            <DeleteEntryBtn
                                                                entryId={
                                                                    entry.id
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </Popover.Content>
                                            </Popover.Portal>
                                        </Popover.Root>
                                    </td>
                                </tr>
                                {expandedRows.has(entry.id) && (
                                    <tr>
                                        <td colSpan={4}>
                                            <SandpackProvider
                                                files={{
                                                    'raw_code.py': {
                                                        code: entry.raw_code,
                                                        active: true,
                                                    },
                                                }}
                                                theme={
                                                    isDarkMode
                                                        ? 'dark'
                                                        : 'light'
                                                }
                                                options={{
                                                    classes: {
                                                        'sp-layout':
                                                            '!border-none',
                                                    },
                                                }}
                                            >
                                                <SandpackLayout>
                                                    <CopyCodeBtn
                                                        code={entry.raw_code}
                                                        baseStyles={`z-10 absolute top-5 right-5 ${btnBaseStyles}`}
                                                    />
                                                    <SandpackCodeViewer
                                                        showTabs={false}
                                                        additionalLanguages={[
                                                            {
                                                                name: 'python',
                                                                extensions: [
                                                                    'py',
                                                                ],
                                                                language:
                                                                    python(),
                                                            },
                                                        ]}
                                                    />
                                                </SandpackLayout>
                                            </SandpackProvider>
                                        </td>
                                    </tr>
                                )}
                            </Fragment>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default Entries;

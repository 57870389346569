import { useEffect } from 'react';
import { useFetcher } from 'react-router-dom';
import { JobState, ScenarioListType, DefaultScenarioListName } from './types';
import ActionAlert from '../../../../../../base/design-system/ActionAlert';
import { ScenarioListPreview } from './types';

interface ScenarioListPreviewProps {
    preview: ScenarioListPreview;
}

function ScenarioListPreview({ preview }: ScenarioListPreviewProps) {
    return (
        <div className="space-y-4 p-4 bg-gray-50 dark:bg-gray-700/30 rounded-lg border border-gray-200 dark:border-gray-600">
            <div>
                <p className="font-medium text-gray-900 dark:text-gray-100">
                    {preview.description ||
                        'This scenario list has no description.'}
                </p>
                <p className="text-sm text-blue-600 dark:text-blue-400">
                    {preview.numScenarios}{' '}
                    {preview.numScenarios === 1 ? 'scenario' : 'scenarios'},{' '}
                    {preview.keys.length}{' '}
                    {preview.keys.length === 1 ? 'key' : 'keys'}
                </p>
            </div>
            <div className="text-sm">
                <p className="font-medium text-gray-900 dark:text-gray-100">
                    Keys:
                </p>
                <ul>
                    {preview.keys.map((key, index) => (
                        <li
                            className="ml-2 list-inside list-decimal"
                            key={index}
                        >
                            {key}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

interface AddScenariosFormProps {
    jobState: JobState;
    handleScenarioSourceChange: (
        value: ScenarioListType,
        defaultListName?: DefaultScenarioListName
    ) => void;
    handleScenarioUrlChange: (url: string) => void;
    handleURLScenarioListPreviewChange: (
        info: (ScenarioListPreview & { uuid: string }) | null
    ) => void;
}

function AddScenariosForm({
    jobState,
    handleScenarioSourceChange,
    handleScenarioUrlChange,
    handleURLScenarioListPreviewChange,
}: AddScenariosFormProps) {
    const fetcher = useFetcher();

    useEffect(() => {
        if (fetcher.data && fetcher.data.success) {
            handleURLScenarioListPreviewChange({
                numScenarios: fetcher.data.num_scenarios,
                description: fetcher.data.description,
                uuid: fetcher.data.uuid,
                keys: fetcher.data.keys,
                firstFiveRows: fetcher.data.first_five_rows,
            });
        }
    }, [fetcher, fetcher.data]);

    const handleDefaultListChange = (listName: DefaultScenarioListName) => {
        handleScenarioSourceChange('default', listName);
    };

    return (
        <div className="space-y-6">
            <div className="space-y-2">
                <p className="font-medium mb-4">
                    How would you like to add scenarios?
                </p>
                <div className="flex items-center">
                    <input
                        type="radio"
                        id="default-scenarios"
                        name="scenario-source"
                        value="default"
                        checked={jobState.scenarioList.type === 'default'}
                        onChange={() => handleScenarioSourceChange('default')}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                    />
                    <label
                        htmlFor="default-scenarios"
                        className="ml-2 text-sm text-gray-900 dark:text-gray-300"
                    >
                        Use a default scenario list
                    </label>
                </div>
                <div className="flex items-center">
                    <input
                        type="radio"
                        id="url-scenarios"
                        name="scenario-source"
                        value="url"
                        checked={jobState.scenarioList.type === 'url'}
                        onChange={() => handleScenarioSourceChange('url')}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                    />
                    <label
                        htmlFor="url-scenarios"
                        className="ml-2 text-sm text-gray-900 dark:text-gray-300"
                    >
                        Add by URL
                    </label>
                </div>
                <div className="flex items-center">
                    <input
                        type="radio"
                        id="no-scenarios"
                        name="scenario-source"
                        value="none"
                        checked={jobState.scenarioList.type === 'none'}
                        onChange={() => handleScenarioSourceChange('none')}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                    />
                    <label
                        htmlFor="no-scenarios"
                        className="ml-2 text-sm text-gray-900 dark:text-gray-300"
                    >
                        Run without scenarios
                    </label>
                </div>
            </div>
            {jobState.scenarioList.type === 'default' && (
                <div className="space-y-2">
                    <p className="font-medium mb-4">
                        Default scenario list options
                    </p>
                    <div className="flex items-center">
                        <input
                            type="radio"
                            id="default-single"
                            name="default-list-size"
                            value="single_scenario"
                            checked={
                                jobState.scenarioList.name === 'single_scenario'
                            }
                            onChange={() =>
                                handleDefaultListChange('single_scenario')
                            }
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                        />
                        <label
                            htmlFor="default-single"
                            className="ml-2 text-sm"
                        >
                            Single scenario
                        </label>
                    </div>
                    <div className="flex items-center">
                        <input
                            type="radio"
                            id="default-multiple"
                            name="default-list-size"
                            value="multiple_scenarios"
                            checked={
                                jobState.scenarioList.name ===
                                'multiple_scenarios'
                            }
                            onChange={() =>
                                handleDefaultListChange('multiple_scenarios')
                            }
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                        />
                        <label
                            htmlFor="default-multiple"
                            className="ml-2 text-sm"
                        >
                            Multiple scenarios
                        </label>
                    </div>
                </div>
            )}
            {jobState.scenarioList.type === 'url' && (
                <div className="space-y-2">
                    <p className="block font-medium text-gray-900 dark:text-gray-100 mb-2">
                        URL
                    </p>
                    <fetcher.Form
                        method="post"
                        className="flex items-center gap-2"
                    >
                        <input
                            name="scenario_list_url"
                            value={jobState.scenarioList.url}
                            onChange={(event) =>
                                handleScenarioUrlChange(event.target.value)
                            }
                            placeholder="URL for scenario or scenario list"
                            className="flex-grow p-2 text-sm bg-gray-50 focus:outline-none border rounded-lg border-gray-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700/20 dark:border-gray-500 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:placeholder-gray-400"
                        />
                        <button
                            name="intent"
                            value="find_scenario_list"
                            className="px-4 py-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        >
                            Find
                        </button>
                    </fetcher.Form>
                    <div className="text-sm">
                        {fetcher.data && !fetcher.data.success && (
                            <ActionAlert response={fetcher.data} />
                        )}
                    </div>
                </div>
            )}
            {(jobState.scenarioList.type === 'default' ||
                jobState.scenarioList.type === 'url') &&
                jobState.scenarioList.preview && (
                    <ScenarioListPreview
                        preview={jobState.scenarioList.preview}
                    />
                )}
        </div>
    );
}

export default AddScenariosForm;

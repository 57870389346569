import { useEffect } from 'react';
import { useFetcher } from 'react-router-dom';
import { JobState, AgentListType } from './types';
import ActionAlert from '../../../../../../base/design-system/ActionAlert';
import {
    AgentListPreview as AgentListPreviewType,
    DefaultAgentListName,
} from './types';

interface AgentListPreviewProps {
    preview: AgentListPreviewType;
}

function AgentListPreview({ preview }: AgentListPreviewProps) {
    return (
        <div className="space-y-4 p-4 bg-gray-50 dark:bg-gray-700/30 rounded-lg border border-gray-200 dark:border-gray-600">
            <div>
                <p className="font-medium text-gray-900 dark:text-gray-100">
                    {preview.description ||
                        'This agent list has no description.'}
                </p>
                <p className="text-sm text-blue-600 dark:text-blue-400">
                    {preview.numAgents}{' '}
                    {preview.numAgents === 1 ? 'agent' : 'agents'},{' '}
                    {preview.traits.length}{' '}
                    {preview.traits.length === 1 ? 'trait' : 'traits'}
                </p>
            </div>
            <div className="text-sm">
                <p className="font-medium text-gray-900 dark:text-gray-100">
                    Traits:
                </p>
                <ul>
                    {preview.traits.map((trait, index) => (
                        <li
                            className="ml-2 list-inside list-decimal"
                            key={index}
                        >
                            {trait}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

interface AddAgentsFormProps {
    jobState: JobState;
    handleAgentSourceChange: (
        value: AgentListType,
        defaultListName?: DefaultAgentListName
    ) => void;
    handleAgentUrlChange: (url: string) => void;
    handleURLAgentListPreviewChange: (
        info: (AgentListPreviewType & { uuid: string }) | null
    ) => void;
}

function AddAgentsForm({
    jobState,
    handleAgentSourceChange,
    handleAgentUrlChange,
    handleURLAgentListPreviewChange,
}: AddAgentsFormProps) {
    const fetcher = useFetcher();

    useEffect(() => {
        if (fetcher.data && fetcher.data.success) {
            handleURLAgentListPreviewChange({
                numAgents: fetcher.data.num_agents,
                description: fetcher.data.description,
                uuid: fetcher.data.uuid,
                traits: fetcher.data.traits,
                firstFiveRows: fetcher.data.first_five_rows,
            });
        }
    }, [fetcher, fetcher.data]);

    const handleDefaultListChange = (listName: DefaultAgentListName) => {
        handleAgentSourceChange('default', listName);
    };

    return (
        <div className="space-y-6">
            <div className="space-y-2">
                <p className="font-medium mb-4">
                    How would you like to add agents?
                </p>
                <div className="flex items-center">
                    <input
                        type="radio"
                        id="default-agents"
                        name="agent-source"
                        value="default"
                        checked={jobState.agentList.type === 'default'}
                        onChange={() => handleAgentSourceChange('default')}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                    />
                    <label
                        htmlFor="default-agents"
                        className="ml-2 text-sm text-gray-900 dark:text-gray-300"
                    >
                        Use a default agent list
                    </label>
                </div>
                <div className="flex items-center">
                    <input
                        type="radio"
                        id="url-agents"
                        name="agent-source"
                        value="url"
                        checked={jobState.agentList.type === 'url'}
                        onChange={() => handleAgentSourceChange('url')}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                    />
                    <label
                        htmlFor="url-agents"
                        className="ml-2 text-sm text-gray-900 dark:text-gray-300"
                    >
                        Add by URL
                    </label>
                </div>
                <div className="flex items-center">
                    <input
                        type="radio"
                        id="no-agents"
                        name="agent-source"
                        value="none"
                        checked={jobState.agentList.type === 'none'}
                        onChange={() => handleAgentSourceChange('none')}
                        className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                    />
                    <label
                        htmlFor="no-agents"
                        className="ml-2 text-sm text-gray-900 dark:text-gray-300"
                    >
                        Run without agents
                    </label>
                </div>
            </div>
            {jobState.agentList.type === 'default' && (
                <div className="space-y-2">
                    <p className="font-medium mb-4">
                        Default agent list options
                    </p>
                    <div className="flex items-center">
                        <input
                            type="radio"
                            id="default-small"
                            name="default-list-size"
                            value="single_agent"
                            checked={jobState.agentList.name === 'single_agent'}
                            onChange={() =>
                                handleDefaultListChange('single_agent')
                            }
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                        />
                        <label htmlFor="default-small" className="ml-2 text-sm">
                            Single agent
                        </label>
                    </div>
                    <div className="flex items-center">
                        <input
                            type="radio"
                            id="default-large"
                            name="default-list-size"
                            value="multiple_agents"
                            checked={
                                jobState.agentList.name === 'multiple_agents'
                            }
                            onChange={() =>
                                handleDefaultListChange('multiple_agents')
                            }
                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500"
                        />
                        <label htmlFor="default-large" className="ml-2 text-sm">
                            Multiple agents
                        </label>
                    </div>
                </div>
            )}
            {jobState.agentList.type === 'url' && (
                <div className="space-y-2">
                    <p className="block font-medium text-gray-900 dark:text-gray-100 mt-4 mb-2">
                        URL
                    </p>
                    <fetcher.Form
                        method="post"
                        className="flex items-center gap-2"
                    >
                        <input
                            name="agent_list_url"
                            value={jobState.agentList.url}
                            onChange={(event) =>
                                handleAgentUrlChange(event.target.value)
                            }
                            placeholder="URL for agent or agent list"
                            className="flex-grow p-2 text-sm bg-gray-50 focus:outline-none border rounded-lg border-gray-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700/20 dark:border-gray-500 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:placeholder-gray-400"
                        />
                        <button
                            name="intent"
                            value="find_agent_list"
                            className="px-4 py-2 text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                        >
                            Find
                        </button>
                    </fetcher.Form>
                    <div className="text-sm">
                        {fetcher.data && !fetcher.data.success && (
                            <ActionAlert response={fetcher.data} />
                        )}
                    </div>
                </div>
            )}
            {(jobState.agentList.type === 'default' ||
                jobState.agentList.type === 'url') &&
                jobState.agentList.preview && (
                    <AgentListPreview preview={jobState.agentList.preview} />
                )}
        </div>
    );
}

export default AddAgentsForm;

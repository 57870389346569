import { Link } from 'react-router-dom';
import Callout from '../../Callout';
import InlineCode from '../../InlineCode';
import CodeBlock from '../../CodeBlock';

function EDSLAPIKeys() {
    const linkStyles =
        'text-blue-500 hover:text-blue-600 hover:underline dark:text-primary-dark-text-accent dark:hover:text-primary-dark-text-accent';

    const edslSettingsImageURL = new URL(
        '../../../../../public/assets/getting-started/edsl-settings.png',
        import.meta.url
    ).pathname;

    return (
        <>
            <div className="space-y-2">
                <h1 className="font-bold text-4xl pb-2">
                    Store API keys for language models
                </h1>
                <p>
                    API keys are required to access the services of large
                    language models (LLMs) such as Anthropic's Claude, Google's
                    Gemini, OpenAI's GPTs, Llama 2, Groq and others. To run
                    surveys with LLMs you can either provide your own API keys (
                    <span className="italic">local inference</span>) or use an
                    Expected Parrot API key to access{' '}
                    <Link
                        to="/getting-started/coop-pricing"
                        className={linkStyles}
                    >
                        all available models
                    </Link>{' '}
                    at once at the Expected Parrot server (
                    <span className="italic">remote inference</span>
                    ). See instructions below for storing your API keys in order
                    to make them available when running surveys.
                </p>
            </div>
            <div className="space-y-2">
                <h2 className="font-bold text-3xl">
                    How to store your API keys
                </h2>
                <p>
                    There are three methods for storing your API keys (details
                    on each method below):
                </p>
                <ul className="list-disc pl-5">
                    <li>
                        Store your keys in the{' '}
                        <Link to="/home/api" className={linkStyles}>
                            Settings
                        </Link>{' '}
                        page of your Coop account.
                    </li>
                    <li>
                        Add your keys to a <InlineCode>.env</InlineCode> file in
                        your working directory.
                    </li>
                    <li>
                        Include your keys directly in your code.{' '}
                        <span className="italic">
                            Not recommended for security reasons.
                        </span>
                    </li>
                </ul>
            </div>
            <div className="space-y-2">
                <h2 className="font-bold text-3xl">
                    Store your keys in your Coop API settings
                </h2>
                <p>
                    <span className="italic">
                        *** This feature is in progress! Please check back soon
                        for updates. ***
                    </span>
                </p>
                <p>
                    This method provides a secure way to store your keys and the
                    most flexibility in choosing how to use them and share
                    access to them with others. To use this method, navigate to
                    the{' '}
                    <Link to="/home/api" className={linkStyles}>
                        Settings
                    </Link>{' '}
                    page of your Coop account and enter any keys that you want
                    to use in the appropriate fields. Choose whether to activate
                    your Expected Parrot API key for remote inference, caching,
                    and logging, and turn on any other services that you want to
                    use. When you do not want to use a key, you can turn it off
                    temporarily or delete it.
                </p>
                <p>
                    You can also optionally grant access to your keys to other
                    Coop users, and set permissions and limits on their use.
                    This allows others to make API calls with your keys, subject
                    to any limitations that you set, but without sharing the
                    keys directly.
                </p>
                <p>You can reset your keys at any time.</p>
            </div>
            <div className="space-y-2">
                <h2 className="font-bold text-3xl">
                    Add your keys to a <InlineCode>.env</InlineCode> file
                </h2>
                <p>
                    This method allows you to privately store your API keys on
                    your machine and make them available for all surveys that
                    you run. To use this method, navigate to your EDSL working
                    directory and create a file named ".env". Populate the file
                    with your keys in the following format:{' '}
                </p>
                <br />
                <CodeBlock>
                    <span>EXPECTED_PARROT_API_KEY</span> ={' '}
                    <span className="text-red-700 dark:text-lime-500">
                        'your_key_here'
                    </span>
                    <br />
                    <br />
                    <span>ANTHROPIC_API_KEY</span> ={' '}
                    <span className="text-red-700 dark:text-lime-500">
                        'your_key_here'
                    </span>
                    <br />
                    <span>DEEP_INFRA_API_KEY</span> ={' '}
                    <span className="text-red-700 dark:text-lime-500">
                        'your_key_here'
                    </span>
                    <br />
                    <span>GOOGLE_API_KEY</span> ={' '}
                    <span className="text-red-700 dark:text-lime-500">
                        'your_key_here'
                    </span>
                    <br />
                    <span>GROQ_API_KEY</span> ={' '}
                    <span className="text-red-700 dark:text-lime-500">
                        'your_key_here'
                    </span>
                    <br />
                    <span>MISTRAL_API_KEY</span> ={' '}
                    <span className="text-red-700 dark:text-lime-500">
                        'your_key_here'
                    </span>
                    <br />
                    <span>OPENAI_API_KEY</span> ={' '}
                    <span className="text-red-700 dark:text-lime-500">
                        'your_key_here'
                    </span>
                    <br />
                    <span>TOGETHER_API_KEY</span> ={' '}
                    <span className="text-red-700 dark:text-lime-500">
                        'your_key_here'
                    </span>
                    <br />
                </CodeBlock>
            </div>
            <div className="space-y-2">
                <p>
                    Your Expected Parrot API key can be found at the{' '}
                    <Link to="/home/api" className={linkStyles}>
                        Settings
                    </Link>{' '}
                    page of your Coop account, where you can reset it at any
                    time. This key allows you to access{' '}
                    <Link
                        to="/getting-started/coop-pricing"
                        className={linkStyles}
                    >
                        all available models
                    </Link>{' '}
                    at once and run surveys remotely at the Expected Parrot
                    server. It also allows you to post content generated locally
                    to Coop, and to interact with other content at Coop that is
                    public or shared with you.
                </p>
                <br></br>
                <Callout title="Tip">
                    See special instructions for{' '}
                    <a
                        href="https://docs.expectedparrot.com/en/latest/colab_setup.html"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={`inline-flex items-center ${linkStyles}`}
                    >
                        using EDSL in Colab
                    </a>
                    .
                </Callout>
            </div>
            <div className="space-y-2">
                <h2 className="font-bold text-3xl">
                    Store your keys in your code (
                    <span className="italic">not recommended</span>)
                </h2>
                <p>
                    This method is not recommended for security reasons. If you
                    include your keys directly in your code, they can be seen by
                    anyone who has access to it. This is especially risky if you
                    are sharing your code with others or if you are using a
                    version control system like Git. To use this method, include
                    your keys as strings in your code in the following format:
                </p>
                <br />
                <CodeBlock>
                    <span>import os</span>
                    <br />
                    <br />
                    <span>os.environ['</span>
                    <span>EXPECTED_PARROT_API_KEY</span>
                    <span>'] = </span>
                    <span className="text-red-700 dark:text-lime-500">
                        'your_key_here'
                    </span>
                    <br />
                    <br />
                    <span>os.environ['</span>
                    <span>ANTHROPIC_API_KEY</span>
                    <span>'] = </span>
                    <span className="text-red-700 dark:text-lime-500">
                        'your_key_here'
                    </span>
                    <br />
                    <span>os.environ['</span>
                    <span>DEEP_INFRA_API_KEY</span>
                    <span>'] = </span>
                    <span className="text-red-700 dark:text-lime-500">
                        'your_key_here'
                    </span>
                    <br />
                    <span>os.environ['</span>
                    <span>GOOGLE_API_KEY</span>
                    <span>'] = </span>
                    <span className="text-red-700 dark:text-lime-500">
                        'your_key_here'
                    </span>
                    <br />
                    <span>os.environ['</span>
                    <span>GROQ_API_KEY</span>
                    <span>'] = </span>
                    <span className="text-red-700 dark:text-lime-500">
                        'your_key_here'
                    </span>
                    <br />
                    <span>os.environ['</span>
                    <span>MISTRAL_API_KEY</span>
                    <span>'] = </span>
                    <span className="text-red-700 dark:text-lime-500">
                        'your_key_here'
                    </span>
                    <br />
                    <span>os.environ['</span>
                    <span>OPENAI_API_KEY</span>
                    <span>'] = </span>
                    <span className="text-red-700 dark:text-lime-500">
                        'your_key_here'
                    </span>
                    <br />
                    <span>os.environ['</span>
                    <span>TOGETHER_API_KEY</span>
                    <span>'] = </span>
                    <span className="text-red-700 dark:text-lime-500">
                        'your_key_here'
                    </span>
                    <br />
                </CodeBlock>
                <br></br>
                <p>
                    Note that your keys will not persist across sessions with
                    this method and you will need to provide your keys each time
                    you start a new session.
                </p>
            </div>
        </>
    );
}

export default EDSLAPIKeys;

import * as Dialog from '@radix-ui/react-dialog';
import { useSubmit } from 'react-router-dom';
import { X } from 'react-feather';
import ActionAlert from '../../../base/design-system/ActionAlert';
import { ActionResponse } from '../../../../types';
import { Field, Scenario } from './types';

function CreateScenarioListModal({
    fields,
    scenarios,
    createScenarioListResponse,
    children,
    method,
    scenarioFile,
}: {
    fields: Field[];
    scenarios: Scenario[];
    createScenarioListResponse: ActionResponse;
    children: React.ReactNode;
    method: 'manual' | 'file';
    scenarioFile: File | null;
}) {
    const submit = useSubmit();

    function handleCreateScenarioList(intent: string) {
        if (method === 'manual') {
            const serializedFields = JSON.stringify(fields);
            const serializedScenarios = JSON.stringify(scenarios);
            submit(
                {
                    fields: serializedFields,
                    scenarios: serializedScenarios,
                    intent: intent,
                },
                { method: 'post', encType: 'application/json' }
            );
        } else {
            // File upload handling
            const formData = new FormData();
            formData.append('file', scenarioFile);
            formData.append('intent', intent);
            submit(formData, {
                method: 'post',
                encType: 'multipart/form-data',
            });
        }
    }

    return (
        <Dialog.Root>
            <Dialog.Trigger asChild>{children}</Dialog.Trigger>
            <Dialog.Portal>
                <Dialog.Overlay className="fixed inset-0 bg-black/60 animate-dialog-overlay-show" />
                <Dialog.Content className="w-[90vw] max-w-[450px] max-h-[85vh] fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 space-y-4 p-8 bg-white dark:bg-gray-700 rounded-md focus:outline-none animate-dialog-content-show">
                    <Dialog.Title asChild>
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            Create scenario list
                        </h3>
                    </Dialog.Title>
                    <Dialog.Description className="text-sm">
                        You can find your new scenario list on your Content
                        page.
                    </Dialog.Description>
                    <div className="text-sm">
                        <div className="flex flex-col gap-4">
                            <button
                                onClick={() => {
                                    if (method === 'manual') {
                                        handleCreateScenarioList(
                                            'create_scenario_list'
                                        );
                                    } else {
                                        handleCreateScenarioList(
                                            'create_scenario_list_from_file'
                                        );
                                    }
                                }}
                                className="self-end text-white px-8 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            >
                                Create
                            </button>
                            <ActionAlert
                                response={createScenarioListResponse}
                            />
                        </div>
                    </div>
                    <Dialog.Close asChild>
                        <button
                            className="absolute top-2.5 right-2.5 h-6 w-6 inline-flex justify-center align-center rounded-full"
                            aria-label="Close"
                        >
                            <X className="inline w-4 h-4" strokeWidth="2" />
                        </button>
                    </Dialog.Close>
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    );
}

export { CreateScenarioListModal };

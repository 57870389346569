import { Link, useLoaderData } from 'react-router-dom';
import { Check, X, Search } from 'react-feather';
import { useState } from 'react';
import ColumnDropdown from './ColumnDropdown';
import FilterDropdown from './FilterDropdown';

interface ModelRecord {
    inference_service: string;
    model: string;
    answer_capital_of_france: string | null;
    answer_image_description: string | null;
    exceptions_capital_of_france: string | null;
    exceptions_image_description: string | null;
    works_with_text: boolean;
    works_with_images: boolean;
    input_price_per_1M_tokens: number | null;
    output_price_per_1M_tokens: number | null;
}

interface CoopPricingData {
    is_authenticated: boolean;
    is_admin: boolean;
    is_beta_user: boolean;
    last_updated_time_from_now: string;
    records: ModelRecord[];
}

const columns = [
    {
        id: 'service',
        label: 'Service',
        default: true,
        group: 'General',
        adminOnly: false,
    },
    {
        id: 'model',
        label: 'Model',
        default: true,
        group: 'General',
        adminOnly: false,
    },
    {
        id: 'textSupport',
        label: 'Text Support',
        default: true,
        group: 'General',
        adminOnly: false,
    },
    {
        id: 'imageSupport',
        label: 'Image Support',
        default: true,
        group: 'General',
        adminOnly: false,
    },
    {
        id: 'inputPrice',
        label: 'Input Price',
        default: true,
        group: 'Pricing',
        adminOnly: false,
    },
    {
        id: 'outputPrice',
        label: 'Output Price',
        default: true,
        group: 'Pricing',
        adminOnly: false,
    },
    {
        id: 'capitalOfFrance',
        label: 'Capital of France',
        default: false,
        group: 'Test Questions',
        adminOnly: false,
    },
    {
        id: 'imageDescription',
        label: 'Logo Description',
        default: false,
        group: 'Test Questions',
        adminOnly: false,
    },
    {
        id: 'capitalOfFranceExceptions',
        label: 'Capital of France',
        default: false,
        group: 'Exceptions',
        adminOnly: true,
    },
    {
        id: 'imageDescriptionExceptions',
        label: 'Logo Description',
        default: false,
        group: 'Exceptions',
        adminOnly: true,
    },
];

function formatPrice(price: number | null) {
    if (price === null) {
        return;
    } else if (price === 0) {
        return `< $0.01`;
    } else {
        return `$${price.toFixed(2)}`;
    }
}

function ModelBadge({ operational }: { operational: boolean }) {
    switch (operational) {
        case true:
            return (
                <div className="flex items-center gap-2 px-2 py-1 rounded-md text-sm font-semibold rounded-lg bg-green-100 text-green-800 dark:bg-green-400/15 dark:text-green-400">
                    <Check className="shrink-0 w-4 h-4" />
                    Works
                </div>
            );
        case false:
            return (
                <div className="flex items-center gap-2 px-2 py-1 rounded-md text-sm font-semibold rounded-lg bg-red-100 text-red-800 dark:bg-red-400/15 dark:text-red-300">
                    <X className="shrink-0 w-4 h-4" />
                    Doesn't work
                </div>
            );
        default:
            return null;
    }
}

function CoopPricing() {
    const data = useLoaderData() as CoopPricingData;
    const [visibleColumns, setVisibleColumns] = useState({
        number: true,
        service: true,
        model: true,
        textSupport: true,
        imageSupport: true,
        inputPrice: true,
        outputPrice: true,
        capitalOfFrance: false,
        imageDescription: false,
        capitalOfFranceExceptions: false,
        imageDescriptionExceptions: false,
    });

    const [filters, setFilters] = useState({
        textSupport: 'all',
        imageSupport: 'all',
    });

    const [searchQuery, setSearchQuery] = useState('');

    const filteredRecords = data.records.filter((record) => {
        const allText = filters.textSupport === 'all';
        const textWorks =
            filters.textSupport === 'works' && record.works_with_text;
        const textDoesntWork =
            filters.textSupport === 'doesnt-work' && !record.works_with_text;
        const textMatch = allText || textWorks || textDoesntWork;

        const allImage = filters.imageSupport === 'all';
        const imageWorks =
            filters.imageSupport === 'works' && record.works_with_images;
        const imageDoesntWork =
            filters.imageSupport === 'doesnt-work' && !record.works_with_images;
        const imageMatch = allImage || imageWorks || imageDoesntWork;

        const searchMatch =
            searchQuery === '' ||
            record.model.toLowerCase().includes(searchQuery.toLowerCase());

        return textMatch && imageMatch && searchMatch;
    });

    const linkStyles =
        'text-blue-500 hover:text-blue-600 hover:underline dark:text-primary-dark-text-accent dark:hover:text-primary-dark-text-accent';

    const handleFilterChange = (filterType: string, value: string) => {
        setFilters((prev) => ({
            ...prev,
            [filterType]: value,
        }));
    };

    const handleColumnChange = (columnId: string, checked: boolean) => {
        setVisibleColumns((prev) => ({
            ...prev,
            [columnId]: checked,
        }));
    };

    return (
        <>
            <div className="space-y-2">
                <h1 className="font-bold text-3xl pb-2">Models</h1>
                <p className="text-lg pb-2">
                    The following model token prices from service providers are
                    used to calculate credits required for running surveys
                    remotely on the Expected Parrot server.{' '}
                    <Link
                        to="https://docs.expectedparrot.com/en/latest/credits.html"
                        target="_blank"
                        className={linkStyles}
                    >
                        Learn more about purchasing and using credits.
                    </Link>
                </p>
            </div>

            <div className="flex justify-between items-center gap-2 mb-4">
                <div className="relative">
                    <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 w-4 h-4 text-gray-400" />
                    <input
                        type="text"
                        placeholder="Search models..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="pl-9 pr-4 py-2 rounded-lg border border-gray-200 dark:border-gray-100/20 bg-white dark:bg-primary-dark-bg focus:outline-none focus:ring-2 focus:ring-blue-500 dark:focus:ring-primary-dark-text-accent text-sm dark:placeholder-gray-400"
                    />
                </div>
                <div className="flex gap-2">
                    <FilterDropdown
                        filters={filters}
                        handleFilterChange={handleFilterChange}
                    />
                    <ColumnDropdown
                        columns={columns}
                        visibleColumns={visibleColumns}
                        handleColumnChange={handleColumnChange}
                        isAdmin={data.is_admin}
                    />
                </div>
            </div>

            <div className="mb-8 min-h-[80vh] overflow-x-auto rounded-lg">
                <div className="relative min-h-[80vh]">
                    <table className="min-w-full text-sm relative">
                        <thead className="bg-white dark:bg-primary-dark-bg sticky border-b dark:border-gray-100/20 top-0">
                            <tr>
                                <th className="py-2 px-4 text-left whitespace-nowrap w-4 font-semibold">
                                    #
                                </th>
                                {visibleColumns.service && (
                                    <th className="py-2 px-4 text-left whitespace-nowrap w-40 font-semibold">
                                        Service
                                    </th>
                                )}
                                {visibleColumns.model && (
                                    <th className="py-2 px-4 text-left whitespace-nowrap w-40 font-semibold">
                                        Model
                                    </th>
                                )}
                                {visibleColumns.textSupport && (
                                    <th className="py-2 px-4 text-left whitespace-nowrap w-32 font-semibold">
                                        Text Support
                                    </th>
                                )}
                                {visibleColumns.imageSupport && (
                                    <th className="py-2 px-4 text-left whitespace-nowrap w-32 font-semibold">
                                        Image Support
                                    </th>
                                )}
                                {visibleColumns.inputPrice && (
                                    <th className="py-2 px-4 text-left w-40 whitespace-nowrap font-semibold">
                                        Input (Price per 1M tokens)
                                    </th>
                                )}
                                {visibleColumns.outputPrice && (
                                    <th className="py-2 px-4 text-left w-40 whitespace-nowrap font-semibold">
                                        Output (Price per 1M tokens)
                                    </th>
                                )}
                                {visibleColumns.capitalOfFrance && (
                                    <th className="py-2 px-4 text-left whitespace-nowrap w-40 font-semibold">
                                        Capital of France (MC)
                                    </th>
                                )}
                                {visibleColumns.imageDescription && (
                                    <th className="py-2 px-4 text-left whitespace-nowrap min-w-[32rem] font-semibold">
                                        Expected Parrot Logo Description (Free
                                        Text)
                                    </th>
                                )}
                                {visibleColumns.capitalOfFranceExceptions && (
                                    <th className="py-2 px-4 text-left whitespace-nowrap w-40 font-semibold">
                                        Capital of France (MC) - Exceptions
                                    </th>
                                )}
                                {visibleColumns.imageDescriptionExceptions && (
                                    <th className="py-2 px-4 text-left whitespace-nowrap min-w-[32rem] font-semibold">
                                        Expected Parrot Logo Description (Free
                                        Text) - Exceptions
                                    </th>
                                )}
                            </tr>
                        </thead>
                        <tbody className="align-top">
                            {filteredRecords.map((record, index) => (
                                <tr
                                    key={index}
                                    className="border-y dark:border-gray-100/20 dark:text-secondary-dark-text"
                                >
                                    <td className="py-2 px-4">{index + 1}</td>
                                    {visibleColumns.service && (
                                        <td className="py-2 px-4">
                                            {record.inference_service}
                                        </td>
                                    )}
                                    {visibleColumns.model && (
                                        <td className="py-2 px-4 whitespace-nowrap">
                                            {record.model}
                                        </td>
                                    )}
                                    {visibleColumns.textSupport && (
                                        <td className="py-2 px-4 whitespace-nowrap">
                                            <ModelBadge
                                                operational={
                                                    record.works_with_text
                                                }
                                            />
                                        </td>
                                    )}
                                    {visibleColumns.imageSupport && (
                                        <td className="py-2 px-4 whitespace-nowrap">
                                            <ModelBadge
                                                operational={
                                                    record.works_with_images
                                                }
                                            />
                                        </td>
                                    )}
                                    {visibleColumns.inputPrice && (
                                        <td className="py-2 px-4">
                                            {formatPrice(
                                                record.input_price_per_1M_tokens
                                            )}
                                        </td>
                                    )}
                                    {visibleColumns.outputPrice && (
                                        <td className="py-2 px-4">
                                            {formatPrice(
                                                record.output_price_per_1M_tokens
                                            )}
                                        </td>
                                    )}
                                    {visibleColumns.capitalOfFrance && (
                                        <td className="py-2 px-4">
                                            {record.answer_capital_of_france}
                                        </td>
                                    )}
                                    {visibleColumns.imageDescription && (
                                        <td
                                            className={`py-2 px-4 ${
                                                record.answer_image_description &&
                                                !record.answer_image_description.includes(
                                                    ' '
                                                )
                                                    ? 'break-all'
                                                    : ''
                                            }`}
                                        >
                                            {record.answer_image_description}
                                        </td>
                                    )}
                                    {visibleColumns.capitalOfFranceExceptions && (
                                        <td className="py-2 px-4 whitespace-pre-line">
                                            {
                                                record.exceptions_capital_of_france
                                            }
                                        </td>
                                    )}
                                    {visibleColumns.imageDescriptionExceptions && (
                                        <td className="py-2 px-4 whitespace-pre-line">
                                            {
                                                record.exceptions_image_description
                                            }
                                        </td>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            <div className="text-sm italic">
                Last updated: {data.last_updated_time_from_now}
            </div>
        </>
    );
}

export default CoopPricing;

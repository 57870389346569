import { useEffect } from 'react';
import { useLoaderData, useRevalidator } from 'react-router-dom';

interface ModelProgress {
    completed: number;
    total: number;
    percent: number;
}

interface BucketInfo {
    completed: number;
    requested: number;
    tokens_returned: number;
    current_rate: number;
    target_rate: number;
}

interface ModelQueueInfo {
    language_model_name: string;
    requests_bucket: BucketInfo;
    tokens_bucket: BucketInfo;
}

interface JobStatus {
    job_id: string;
    overall_progress: {
        percent: number;
        completed: number;
        total: number;
    };
    status: 'idle' | 'running' | 'completed';
    language_model_progress: Record<string, ModelProgress>;
    statistics: Record<string, string | number>;
    language_model_queues: Record<string, ModelQueueInfo>;
}

interface StatusData {
    is_authenticated: boolean;
    is_beta_user: boolean;
    status: JobStatus;
}

function RemoteJobId() {
    const data = useLoaderData() as StatusData;
    const status = data.status;

    let revalidator = useRevalidator();

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (revalidator.state === 'idle' && status.status === 'running') {
                revalidator.revalidate();
            } else if (
                status.status === 'completed' ||
                status.status === 'idle'
            ) {
                // Clear interval if job is completed
                clearInterval(intervalId);
            }
            // Revalidate every 1000 ms (1 second)
        }, 1000);

        // Cleanup function to clear the interval when component unmounts
        return () => clearInterval(intervalId);
    }, [revalidator, status.status]);

    return (
        <div className="container mx-auto px-4 py-8">
            <div className="rounded-lg shadow-md p-6">
                <div className="mb-6">
                    <h2 className="text-xl font-bold mb-4">Overall Progress</h2>
                    <div className="w-full bg-gray-200 rounded h-4 mb-2">
                        <div
                            className="bg-blue-600 h-4 rounded transition-all duration-300"
                            style={{
                                width: `${status.overall_progress.percent}%`,
                            }}
                        />
                    </div>
                    <div className="flex justify-between text-sm text-gray-600 dark:text-gray-400">
                        <span>
                            {status.overall_progress.completed}/
                            {status.overall_progress.total}
                        </span>
                        <span>{status.overall_progress.percent}%</span>
                    </div>
                    <div className="mt-2 text-sm">
                        Status: <span>{status.status}</span>
                    </div>
                </div>

                <div className="mb-6">
                    <h2 className="text-xl font-bold mb-4">Model Progress</h2>
                    <div>
                        {Object.entries(status.language_model_progress).map(
                            ([model, progress]) => (
                                <div key={model} className="mb-4">
                                    <div className="flex justify-between mb-1">
                                        <span className="text-sm font-medium">
                                            {model}
                                        </span>
                                        <span className="text-sm">
                                            {progress.percent}%
                                        </span>
                                    </div>
                                    <div className="w-full bg-gray-200 rounded h-2">
                                        <div
                                            className="bg-blue-600 h-2 rounded"
                                            style={{
                                                width: `${progress.percent}%`,
                                            }}
                                        />
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                </div>

                <div className="mb-6">
                    <h2 className="text-xl font-bold mb-4">Statistics</h2>
                    <div>
                        {Object.entries(status.statistics).map(
                            ([key, value]) => (
                                <div key={key} className="mb-4">
                                    <div className="text-sm font-medium mb-1">
                                        {key}
                                    </div>
                                    <div className="text-lg">{value}</div>
                                </div>
                            )
                        )}
                    </div>
                </div>

                <div className="mb-6">
                    <h2 className="text-xl font-bold mb-4">Model Queues</h2>
                    <div className="space-y-6">
                        {Object.entries(status.language_model_queues).map(
                            ([modelName, queueInfo]) => (
                                <div key={modelName}>
                                    <h3 className="text-lg font-semibold text-blue-600 dark:text-blue-400 mb-3">
                                        {modelName}
                                    </h3>

                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                        {/* Requests Bucket */}
                                        <div className="p-3 rounded shadow-sm">
                                            <h4 className="font-medium text-gray-700 dark:text-gray-300 mb-2">
                                                Requests Bucket
                                            </h4>
                                            <div className="space-y-1 text-sm">
                                                <div className="flex justify-between">
                                                    <span>
                                                        Completed/Requested:
                                                    </span>
                                                    <span>
                                                        {
                                                            queueInfo
                                                                .requests_bucket
                                                                .completed
                                                        }
                                                        /
                                                        {
                                                            queueInfo
                                                                .requests_bucket
                                                                .requested
                                                        }
                                                    </span>
                                                </div>
                                                <div className="flex justify-between">
                                                    <span>
                                                        Tokens Returned:
                                                    </span>
                                                    <span>
                                                        {
                                                            queueInfo
                                                                .requests_bucket
                                                                .tokens_returned
                                                        }
                                                    </span>
                                                </div>
                                                <div className="flex justify-between text-blue-600 dark:text-blue-400">
                                                    <span>
                                                        RPM (Current/Target):
                                                    </span>
                                                    <span>
                                                        {
                                                            queueInfo
                                                                .requests_bucket
                                                                .current_rate
                                                        }
                                                        /
                                                        {
                                                            queueInfo
                                                                .requests_bucket
                                                                .target_rate
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Tokens Bucket */}
                                        <div className="p-3 rounded shadow-sm">
                                            <h4 className="font-medium text-gray-700 dark:text-gray-300 mb-2">
                                                Tokens Bucket
                                            </h4>
                                            <div className="space-y-1 text-sm">
                                                <div className="flex justify-between">
                                                    <span>
                                                        Completed/Requested:
                                                    </span>
                                                    <span>
                                                        {
                                                            queueInfo
                                                                .tokens_bucket
                                                                .completed
                                                        }
                                                        /
                                                        {
                                                            queueInfo
                                                                .tokens_bucket
                                                                .requested
                                                        }
                                                    </span>
                                                </div>
                                                <div className="flex justify-between">
                                                    <span>
                                                        Tokens Returned:
                                                    </span>
                                                    <span>
                                                        {
                                                            queueInfo
                                                                .tokens_bucket
                                                                .tokens_returned
                                                        }
                                                    </span>
                                                </div>
                                                <div className="flex justify-between text-blue-600 dark:text-blue-400">
                                                    <span>
                                                        TPM (Current/Target):
                                                    </span>
                                                    <span>
                                                        {
                                                            queueInfo
                                                                .tokens_bucket
                                                                .current_rate
                                                        }
                                                        /
                                                        {
                                                            queueInfo
                                                                .tokens_bucket
                                                                .target_rate
                                                        }
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default RemoteJobId;

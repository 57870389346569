import * as Dialog from '@radix-ui/react-dialog';
import { useFetcher } from 'react-router-dom';
import { X } from 'react-feather';
import ActionAlert from '../../../base/design-system/ActionAlert';

function AddProjectModal({ children }: { children: React.ReactNode }) {
    const fetcher = useFetcher();

    return (
        <Dialog.Root>
            <Dialog.Trigger asChild>{children}</Dialog.Trigger>
            <Dialog.Portal>
                <Dialog.Overlay className="fixed inset-0 bg-black/60 animate-dialog-overlay-show" />
                <Dialog.Content className="w-[90vw] max-w-[450px] max-h-[85vh] fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 space-y-4 p-8 bg-white dark:bg-gray-700 rounded-md focus:outline-none animate-dialog-content-show">
                    <Dialog.Title asChild>
                        <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                            Create project
                        </h3>
                    </Dialog.Title>
                    <Dialog.Description className="text-sm">
                        Create a new project based on an existing survey. You
                        can then share your survey with participants and collect
                        responses.
                    </Dialog.Description>
                    <div className="text-sm">
                        <fetcher.Form
                            className="flex flex-col gap-4"
                            method="post"
                        >
                            <div>
                                <label
                                    htmlFor="new-project-name"
                                    className="block mb-2 text-base font-medium text-gray-900 dark:text-white"
                                >
                                    Project name
                                </label>
                                <input
                                    id="new-project-name"
                                    name="name"
                                    className="block p-2.5 w-full text-sm bg-gray-50 focus:outline-none border rounded-lg border-gray-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700/20 dark:border-gray-500 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:placeholder-gray-400"
                                    defaultValue=""
                                />
                            </div>
                            <div>
                                <label
                                    htmlFor="new-project-survey-url"
                                    className="block mb-2 text-base font-medium text-gray-900 dark:text-white"
                                >
                                    Survey URL
                                </label>
                                <input
                                    id="new-project-survey-url"
                                    name="survey_url"
                                    className="block p-2.5 w-full text-sm bg-gray-50 focus:outline-none border rounded-lg border-gray-400 focus:ring-1 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700/20 dark:border-gray-500 dark:focus:ring-blue-500 dark:focus:border-blue-500 dark:placeholder-gray-400"
                                    defaultValue=""
                                />
                            </div>
                            <button
                                type="submit"
                                name="intent"
                                value="add"
                                className="self-end text-white px-8 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                            >
                                Add
                            </button>
                            <ActionAlert response={fetcher.data} />
                        </fetcher.Form>
                    </div>

                    <Dialog.Close asChild>
                        <button
                            className="absolute top-2.5 right-2.5 h-6 w-6 inline-flex justify-center align-center rounded-full"
                            aria-label="Close"
                        >
                            <X className="inline w-4 h-4" strokeWidth="2" />
                        </button>
                    </Dialog.Close>
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    );
}

export default AddProjectModal;

import { Check, ChevronDown } from 'react-feather';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

const filterOptions = [
    { value: 'all', label: 'All' },
    { value: 'works', label: 'Works' },
    { value: 'doesnt-work', label: "Doesn't work" },
];

function FilterSection({ label, value, onChange }) {
    return (
        <>
            <DropdownMenu.Label className="px-2 py-1.5 text-sm font-semibold">
                {label}
            </DropdownMenu.Label>
            <DropdownMenu.RadioGroup value={value} onValueChange={onChange}>
                {filterOptions.map((option) => (
                    <DropdownMenu.RadioItem
                        key={option.value}
                        value={option.value}
                        className="relative flex items-center gap-2 px-8 py-1.5 select-none outline-none cursor-default hover:bg-gray-100 dark:hover:bg-gray-100/5 rounded text-sm"
                    >
                        <DropdownMenu.ItemIndicator className="absolute left-2">
                            <Check className="w-4 h-4" />
                        </DropdownMenu.ItemIndicator>
                        {option.label}
                    </DropdownMenu.RadioItem>
                ))}
            </DropdownMenu.RadioGroup>
        </>
    );
}

function FilterDropdown({ filters, handleFilterChange }) {
    return (
        <DropdownMenu.Root modal={false}>
            <DropdownMenu.Trigger className="flex items-center gap-2 px-3 py-2 rounded-lg border border-gray-200 dark:border-gray-100/20 hover:bg-gray-50 dark:hover:bg-gray-100/5 text-sm font-semibold">
                Filters
                <ChevronDown className="w-4 h-4" />
            </DropdownMenu.Trigger>
            <DropdownMenu.Portal>
                <DropdownMenu.Content className="bg-white dark:bg-primary-dark-bg rounded-lg shadow-lg border border-gray-200 dark:border-gray-100/20 p-2 min-w-[200px]">
                    <FilterSection
                        label="Text Support"
                        value={filters.textSupport}
                        onChange={(value: string) =>
                            handleFilterChange('textSupport', value)
                        }
                    />
                    <DropdownMenu.Separator className="h-px bg-gray-200 dark:bg-gray-100/20 my-2" />
                    <FilterSection
                        label="Image Support"
                        value={filters.imageSupport}
                        onChange={(value: string) =>
                            handleFilterChange('imageSupport', value)
                        }
                    />
                </DropdownMenu.Content>
            </DropdownMenu.Portal>
        </DropdownMenu.Root>
    );
}

export default FilterDropdown;

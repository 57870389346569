import { useState } from 'react';
import { useFetcher } from 'react-router-dom';
import * as Dialog from '@radix-ui/react-dialog';
import * as Tabs from '@radix-ui/react-tabs';
import * as VisuallyHidden from '@radix-ui/react-visually-hidden';
import AddAgentsForm from './AddAgentsForm';
import AddModelsForm from './AddModelsForm';
import AddScenariosForm from './AddScenariosForm';
import { Play, X } from 'react-feather';
import {
    JobState,
    DefaultAgentListName,
    AgentListPreview,
    AgentListType,
    ScenarioListType,
    DefaultScenarioListName,
    ScenarioListPreview,
    ModelListType,
    ModelListPreview,
} from './types';

const DEFAULT_AGENT_LISTS = {
    single_agent: {
        numAgents: 1,
        description: 'Single agent list',
        name: 'single_agent',
        traits: ['age', 'height', 'hair'],
        firstFiveRows: [{ age: '22', height: '5.5', hair: 'brown' }],
        data: [{ age: '22', height: '5.5', hair: 'brown' }],
    },
    multiple_agents: {
        numAgents: 10,
        description: 'Multiple agent list',
        name: 'multiple_agents',
        traits: ['age', 'gender', 'country'],
        firstFiveRows: [
            { age: '20', gender: 'male', country: 'USA' },
            { age: '20', gender: 'female', country: 'USA' },
            { age: '30', gender: 'male', country: 'USA' },
            { age: '30', gender: 'female', country: 'USA' },
            { age: '40', gender: 'male', country: 'USA' },
        ],
        data: Array.from({ length: 10 }, (_, i) => {
            const age = Math.floor(i / 2) * 10 + 20; // Will give us 20,20,30,30,40,40,50,50,60,60
            const gender = i % 2 === 0 ? 'male' : 'female';
            return {
                age: age.toString(),
                gender: gender,
                country: 'USA',
            };
        }),
    },
};

const DEFAULT_SCENARIO_LISTS = {
    single_scenario: {
        numScenarios: 1,
        description: 'Single scenario list',
        name: 'single_scenario',
        keys: ['time', 'location', 'weather'],
        firstFiveRows: [
            {
                time: '10:00',
                location: 'New York',
                weather: 'sunny',
            },
        ],
    },
    multiple_scenarios: {
        numScenarios: 10,
        description: 'Multiple scenario list',
        name: 'multiple_scenarios',
        keys: ['activity'],
        firstFiveRows: [
            {
                activity: 'shopping',
            },
            {
                activity: 'dining',
            },
            {
                activity: 'going to museums',
            },
            {
                activity: 'playing tennis',
            },
            {
                activity: 'relaxing',
            },
        ],
    },
};

interface AgentPreviewProps {
    traits: string[];
    agents: Array<Record<string, string>>;
}

function AgentPreview({ traits, agents }: AgentPreviewProps) {
    if (!traits.length || !agents.length) {
        return null;
    }

    return (
        <div className="mb-4 overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="px-6 py-3">
                            Agent
                        </th>
                        {traits.map((trait, index) => (
                            <th key={index} scope="col" className="px-6 py-3">
                                {trait}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {agents.map((agent, index) => (
                        <tr
                            key={index}
                            className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                        >
                            <th
                                scope="row"
                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                            >
                                Agent #{index + 1}
                            </th>
                            {traits.map((trait, traitIndex) => (
                                <td key={traitIndex} className="px-6 py-4">
                                    {agent[trait]}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

interface ScenarioPreviewProps {
    keys: string[];
    scenarios: Array<Record<string, string>>;
}

function ScenarioPreview({ keys, scenarios }: ScenarioPreviewProps) {
    if (!keys.length || !scenarios.length) {
        return null;
    }

    return (
        <div className="mb-4 overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="px-6 py-3">
                            Scenario
                        </th>
                        {keys.map((key, index) => (
                            <th key={index} scope="col" className="px-6 py-3">
                                {key}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {scenarios.map((scenario, index) => (
                        <tr
                            key={index}
                            className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                        >
                            <th
                                scope="row"
                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                            >
                                Scenario #{index + 1}
                            </th>
                            {keys.map((key, keyIndex) => (
                                <td key={keyIndex} className="px-6 py-4">
                                    {scenario[key]}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

interface ModelPreviewProps {
    models: Array<{ inference_service: string; model_name: string }>;
}

function ModelPreview({ models }: ModelPreviewProps) {
    if (!models.length) {
        return null;
    }

    return (
        <div className="mb-4 overflow-x-auto">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="px-6 py-3">
                            Model
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Inference Service
                        </th>
                        <th scope="col" className="px-6 py-3">
                            Model Name
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {models.map((model, index) => (
                        <tr
                            key={index}
                            className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                        >
                            <th
                                scope="row"
                                className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                            >
                                Model #{index + 1}
                            </th>
                            <td className="px-6 py-4">
                                {model.inference_service}
                            </td>
                            <td className="px-6 py-4">{model.model_name}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

function NewJobModal({
    projectId,
    availableModels,
    children,
}: {
    projectId: string;
    availableModels: {
        inference_service: string;
        models: string[];
    }[];
    children: React.ReactNode;
}) {
    const fetcher = useFetcher();
    const [jobState, setJobState] = useState<JobState>(() => {
        return {
            agentList: {
                type: 'default',
                name: 'single_agent',
                preview: DEFAULT_AGENT_LISTS.single_agent,
            },
            scenarioList: {
                type: 'default',
                name: 'single_scenario',
                preview: DEFAULT_SCENARIO_LISTS.single_scenario,
            },
            modelList: {
                type: 'selected',
                models: [],
            },
        };
    });

    // Update the handler to work with new state structure
    const handleURLAgentListPreviewChange = (
        previewData: (AgentListPreview & { uuid: string }) | null
    ) => {
        setJobState((prevState) => ({
            ...prevState,
            agentList: {
                type: 'url',
                url:
                    prevState.agentList.type === 'url'
                        ? prevState.agentList.url
                        : '',
                uuid: previewData?.uuid || null,
                preview: previewData,
            },
        }));
    };

    const handleAgentSourceChange = (
        value: AgentListType,
        defaultListName?: DefaultAgentListName
    ) => {
        setJobState((prevState) => {
            if (value === 'none') {
                return {
                    ...prevState,
                    agentList: { type: 'none' },
                };
            } else if (value === 'default' && defaultListName) {
                return {
                    ...prevState,
                    agentList: {
                        type: 'default',
                        name: defaultListName,
                        preview: DEFAULT_AGENT_LISTS[defaultListName],
                    },
                };
            } else if (value === 'url') {
                return {
                    ...prevState,
                    agentList: {
                        type: 'url',
                        url: '',
                        uuid: null,
                        preview: null,
                    },
                };
            }

            // Default fallback
            return {
                ...prevState,
                agentList: {
                    type: 'default',
                    name: 'single_agent',
                    preview: DEFAULT_AGENT_LISTS.single_agent,
                },
            };
        });
    };

    const handleScenarioSourceChange = (
        value: ScenarioListType,
        defaultListName?: DefaultScenarioListName
    ) => {
        setJobState((prevState) => {
            if (value === 'none') {
                return {
                    ...prevState,
                    scenarioList: { type: 'none' },
                };
            } else if (value === 'default' && defaultListName) {
                return {
                    ...prevState,
                    scenarioList: {
                        type: 'default',
                        name: defaultListName,
                        preview: DEFAULT_SCENARIO_LISTS[defaultListName],
                    },
                };
            } else if (value === 'url') {
                return {
                    ...prevState,
                    scenarioList: {
                        type: 'url',
                        url: '',
                        uuid: null,
                        preview: null,
                    },
                };
            }

            // Default fallback
            return {
                ...prevState,
                scenarioList: {
                    type: 'default',
                    name: 'single_scenario',
                    preview: DEFAULT_SCENARIO_LISTS.single_scenario,
                },
            };
        });
    };

    const handleScenarioUrlChange = (url: string) => {
        setJobState((prevState) => ({
            ...prevState,
            scenarioList: {
                ...prevState.scenarioList,
                url: url,
            },
        }));
    };

    const handleURLScenarioListPreviewChange = (
        previewData: (ScenarioListPreview & { uuid: string }) | null
    ) => {
        setJobState((prevState) => ({
            ...prevState,
            scenarioList: {
                type: 'url',
                url:
                    prevState.scenarioList.type === 'url'
                        ? prevState.scenarioList.url
                        : '',
                uuid: previewData?.uuid || null,
                preview: previewData,
            },
        }));
    };

    // Model lists

    const handleModelSourceChange = (value: ModelListType) => {
        setJobState((prevState) => {
            if (value === 'selected') {
                return {
                    ...prevState,
                    modelList: {
                        type: 'selected',
                        models: [],
                    },
                };
            } else if (value === 'url') {
                return {
                    ...prevState,
                    modelList: {
                        type: 'url',
                        models: [],
                        url: '',
                        uuid: null,
                        preview: null,
                    },
                };
            }

            // Default fallback
            return {
                ...prevState,
                modelList: {
                    type: 'selected',
                    models: [],
                },
            };
        });
    };

    const handleModelUrlChange = (url: string) => {
        setJobState((prevState) => ({
            ...prevState,
            modelList: {
                ...prevState.modelList,
                url: url,
            },
        }));
    };

    const handleSelectedModelsChange = (
        models: { inference_service: string; model_name: string }[]
    ) => {
        setJobState((prevState) => ({
            ...prevState,
            modelList: {
                type: 'selected',
                models: models,
            },
        }));
    };

    const handleURLModelListPreviewChange = (
        previewData:
            | (ModelListPreview & {
                  uuid: string;
                  models: { inference_service: string; model_name: string }[];
              })
            | null
    ) => {
        setJobState((prevState) => ({
            ...prevState,
            modelList: {
                type: 'url',
                url:
                    prevState.modelList.type === 'url'
                        ? prevState.modelList.url
                        : '',
                uuid: previewData?.uuid || null,
                preview: previewData
                    ? {
                          numModels: previewData.numModels,
                          description: previewData.description,
                      }
                    : null,
                models: previewData?.models || [],
            },
        }));
    };

    // Submitting the job

    function getNewJobFeedback() {
        if (!fetcher.data) {
            return;
        }
        if (fetcher.data.includes('success')) {
            return (
                <p className="text-sm text-green-600 dark:text-green-400">
                    {fetcher.data}
                </p>
            );
        } else {
            return (
                <p className="text-sm text-red-600 dark:text-red-600">
                    {fetcher.data}
                </p>
            );
        }
    }

    function handleSubmit() {
        const formData = new FormData();
        formData.append('agentList', JSON.stringify(jobState.agentList));
        formData.append('scenarioList', JSON.stringify(jobState.scenarioList));
        formData.append('modelList', JSON.stringify(jobState.modelList));
        formData.append('projectId', projectId);
        formData.append('intent', 'create_job');
        fetcher.submit(formData, {
            method: 'post',
            encType: 'multipart/form-data',
        });
    }

    return (
        <Dialog.Root>
            <Dialog.Trigger asChild>{children}</Dialog.Trigger>
            <Dialog.Portal>
                <Dialog.Overlay className="fixed inset-0 bg-black/60 animate-dialog-overlay-show" />
                <Dialog.Content className="overflow-y-auto w-[90vw] max-w-3xl h-[85vh] fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 space-y-4 px-8 pt-4 pb-8 bg-white dark:bg-gray-700 rounded-md focus:outline-none animate-dialog-content-show">
                    <VisuallyHidden.Root>
                        <Dialog.Title>
                            Run with agents and scenarios
                        </Dialog.Title>
                        <Dialog.Description>
                            Create a new remote inference job to run your survey
                            with AI agents.
                        </Dialog.Description>
                    </VisuallyHidden.Root>
                    <Tabs.Root defaultValue="add_agents">
                        <Tabs.List
                            className="flex gap-x-2 mb-4 border-b border-gray-200 dark:border-gray-700"
                            aria-label="Switch tabs"
                        >
                            <Tabs.Trigger
                                className="p-4 border-b-2 rounded-t-lg text-sm font-medium text-center data-[state=active]:text-blue-600 data-[state=active]:border-blue-600 data-[state=inactive]:border-transparent dark:data-[state=active]:text-primary-dark-text-accent dark:data-[state=active]:border-primary-dark-text-accent"
                                value="add_agents"
                            >
                                Add agents
                            </Tabs.Trigger>
                            <Tabs.Trigger
                                className="p-4 border-b-2 rounded-t-lg text-sm font-medium text-center data-[state=active]:text-blue-600 data-[state=active]:border-blue-600 data-[state=inactive]:border-transparent dark:data-[state=active]:text-primary-dark-text-accent dark:data-[state=active]:border-primary-dark-text-accent"
                                value="add_scenarios"
                            >
                                Add scenarios
                            </Tabs.Trigger>
                            <Tabs.Trigger
                                className="p-4 border-b-2 rounded-t-lg text-sm font-medium text-center data-[state=active]:text-blue-600 data-[state=active]:border-blue-600 data-[state=inactive]:border-transparent dark:data-[state=active]:text-primary-dark-text-accent dark:data-[state=active]:border-primary-dark-text-accent"
                                value="add_models"
                            >
                                Add models
                            </Tabs.Trigger>
                            <Tabs.Trigger
                                className="p-4 border-b-2 rounded-t-lg text-sm font-medium text-center data-[state=active]:text-blue-600 data-[state=active]:border-blue-600 data-[state=inactive]:border-transparent dark:data-[state=active]:text-primary-dark-text-accent dark:data-[state=active]:border-primary-dark-text-accent"
                                value="run"
                            >
                                Run
                            </Tabs.Trigger>
                        </Tabs.List>
                        <Tabs.Content value="add_agents">
                            <h3 className="mb-6 text-xl font-semibold text-gray-900 dark:text-white">
                                Add agents
                            </h3>
                            <AddAgentsForm
                                jobState={jobState}
                                handleAgentSourceChange={
                                    handleAgentSourceChange
                                }
                                handleAgentUrlChange={(url) =>
                                    setJobState((prevState) => ({
                                        ...prevState,
                                        agentList: {
                                            ...prevState.agentList,
                                            url: url,
                                        },
                                    }))
                                }
                                handleURLAgentListPreviewChange={
                                    handleURLAgentListPreviewChange
                                }
                            />
                        </Tabs.Content>
                        <Tabs.Content value="add_scenarios">
                            <h3 className="mb-6 text-xl font-semibold text-gray-900 dark:text-white">
                                Add scenarios
                            </h3>
                            <AddScenariosForm
                                jobState={jobState}
                                handleScenarioSourceChange={
                                    handleScenarioSourceChange
                                }
                                handleScenarioUrlChange={
                                    handleScenarioUrlChange
                                }
                                handleURLScenarioListPreviewChange={
                                    handleURLScenarioListPreviewChange
                                }
                            />
                        </Tabs.Content>
                        <Tabs.Content value="add_models">
                            <h3 className="mb-6 text-xl font-semibold text-gray-900 dark:text-white">
                                Add models
                            </h3>
                            <AddModelsForm
                                jobState={jobState}
                                availableModels={availableModels}
                                handleModelSourceChange={
                                    handleModelSourceChange
                                }
                                handleModelUrlChange={handleModelUrlChange}
                                handleSelectedModelsChange={
                                    handleSelectedModelsChange
                                }
                                handleURLModelListPreviewChange={
                                    handleURLModelListPreviewChange
                                }
                            />
                        </Tabs.Content>
                        <Tabs.Content value="run">
                            <div className="space-y-6">
                                <h3 className="mb-6 text-xl font-semibold text-gray-900 dark:text-white">
                                    Run
                                </h3>
                                <p className="text-sm">
                                    Create a new remote inference job to run
                                    your survey with AI agents.
                                </p>
                                <div>
                                    <p className="mb-4 text-lg font-semibold text-gray-900 dark:text-white">
                                        Agent preview
                                    </p>
                                    {jobState.agentList.type === 'none' ? (
                                        <p className="text-sm">
                                            You've chosen to run without agents.
                                        </p>
                                    ) : (
                                        jobState.agentList.preview && (
                                            <AgentPreview
                                                traits={
                                                    jobState.agentList.preview
                                                        .traits
                                                }
                                                agents={
                                                    jobState.agentList.preview
                                                        .firstFiveRows
                                                }
                                            />
                                        )
                                    )}
                                </div>
                                <div>
                                    <p className="mb-4 text-lg font-semibold text-gray-900 dark:text-white">
                                        Scenario preview
                                    </p>
                                    {jobState.scenarioList.type === 'none' ? (
                                        <p className="text-sm">
                                            You've chosen to run without
                                            scenarios.
                                        </p>
                                    ) : (
                                        jobState.scenarioList.preview && (
                                            <ScenarioPreview
                                                keys={
                                                    jobState.scenarioList
                                                        .preview.keys
                                                }
                                                scenarios={
                                                    jobState.scenarioList
                                                        .preview.firstFiveRows
                                                }
                                            />
                                        )
                                    )}
                                </div>
                                <div>
                                    <p className="mb-4 text-lg font-semibold text-gray-900 dark:text-white">
                                        Model preview
                                    </p>
                                    {jobState.modelList.models.length > 0 ? (
                                        <ModelPreview
                                            models={jobState.modelList.models}
                                        />
                                    ) : (
                                        <p className="text-sm">
                                            You haven't selected any models.
                                            Your job will be run with the
                                            default model: OpenAI's gpt-4o-mini.
                                        </p>
                                    )}
                                </div>
                                <div className="text-sm flex flex-col gap-4">
                                    <input
                                        type="hidden"
                                        name="project_uuid"
                                        value={projectId}
                                    />
                                    <button
                                        type="submit"
                                        onClick={handleSubmit}
                                        className="flex items-center self-end text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                                    >
                                        <Play className="w-4 mr-2" />
                                        Run
                                    </button>
                                    {getNewJobFeedback()}
                                </div>
                            </div>
                        </Tabs.Content>
                    </Tabs.Root>
                    <Dialog.Close asChild>
                        <button
                            className="absolute top-2.5 right-2.5 h-6 w-6 inline-flex justify-center align-center rounded-full"
                            aria-label="Close"
                        >
                            <X className="inline w-4 h-4" strokeWidth="2" />
                        </button>
                    </Dialog.Close>
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    );
}

export default NewJobModal;

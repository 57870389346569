import { Link, useOutletContext } from 'react-router-dom';
// import EPLogo from './EPLogo';

function AppIndex() {
    const isDarkMode = useOutletContext();

    return (
        <div className="flex flex-col h-full w-full items-center justify-start pt-20">
            <div className="flex flex-col items-start justify-start w-full max-w-7xl px-10">
                {/* <div className="flex-none">
                    <EPLogo
                        isDarkMode={isDarkMode}
                        className="w-24 sm:w-60 h-24 sm:h-60" // Adjust size as needed
                    />
                </div> */}
                <div className="flex-grow ml-10 sm:ml-20"> {/* Increased left margin for text */}
                    <br></br>
                    <h1 className="text-4xl sm:text-8xl font-bold text-gray-500 dark:text-primary-dark-text">
                        Expected Parrot
                    </h1>
                    <p className="text-3xl sm:text-6xl font-bold text-green-700 dark:text-primary-dark-text mt-2 sm:mt-4">
                        AI-powered research
                    </p>
                    {/*<Link
                        to="/getting-started"
                        className="bg-green-700 hover:bg-green-800 border-2 border-green-700 hover:border-green-800 rounded-lg px-10 py-3 text-xl sm:text-3xl text-white font-semibold mt-6 sm:mt-12" // Increased top margin
                    >
                        Get started
                    </Link>*/}
                </div>
            </div>
        </div>
    );
}

export default AppIndex;

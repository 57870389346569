import { useFetcher } from 'react-router-dom';
import { Trash2 } from 'react-feather';

function DeleteUnverifiedUsersForm() {
    const fetcher = useFetcher();

    return (
        <fetcher.Form method="post">
            <button
                name="intent"
                value="delete_unverified_users"
                className="flex items-center gap-2 px-6 py-2.5 bg-red-600 hover:bg-red-700 hover:transition-colors rounded-md text-sm text-white font-medium"
            >
                <Trash2 className="w-4 h-4" />
                Delete unverified users
            </button>
        </fetcher.Form>
    );
}

export default DeleteUnverifiedUsersForm;

import { useLoaderData, Link } from 'react-router-dom';
import { Hash } from 'react-feather';
import Pagination from '../../../base/Pagination';
import { formatDate } from '../../../../utils';

interface RemoteCacheData {
    total_cache_entries: number;
    cache_logs: {
        created_ts: number;
        description: string;
        cache_entry_count: number;
    }[];
    cache_logs_count: number;
    current_page: number;
    page_size: number;
    total_pages: number;
    is_admin: boolean;
}

function RemoteCache() {
    const data = useLoaderData() as RemoteCacheData;

    const startIndex = (data.current_page - 1) * data.page_size + 1;
    const endIndex = Math.min(
        startIndex + data.page_size - 1,
        data.cache_logs_count
    );

    const cacheEntriesLink = data.is_admin
        ? '/admin/remote-cache/entries'
        : '/home/remote-cache/entries';

    return (
        <>
            <div className="space-y-2">
                <h1 className="font-bold text-3xl pb-2">Remote Cache</h1>
                <p className="text-lg pb-2">
                    Manage your remote cache entries.
                </p>
                <div>
                    {data.total_cache_entries > 0 ? (
                        <div className="bg-white dark:bg-gray-800 rounded-lg border border-gray-200 dark:border-gray-500 w-fit">
                            <div className="p-6">
                                <div className="flex items-start">
                                    <div>
                                        <h3 className="font-medium text-gray-900 dark:text-gray-100">
                                            Entries in remote cache
                                        </h3>
                                        <p className="mt-2 text-3xl font-semibold">
                                            {data.total_cache_entries}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="border-t border-gray-200 dark:border-gray-500 px-6 py-3 bg-gray-50 dark:bg-gray-800 rounded-b-lg">
                                <Link
                                    to={cacheEntriesLink}
                                    className="text-sm text-blue-600 dark:text-blue-400 hover:text-blue-700 dark:hover:text-blue-300"
                                >
                                    View all
                                </Link>
                            </div>
                        </div>
                    ) : (
                        <>
                            <p className="text-2xl">
                                You don't have any remote cache entries 😔
                            </p>
                            <p className="text-2xl">
                                How about{' '}
                                <Link
                                    to="/home/api"
                                    className="text-blue-500 hover:text-blue-600 dark:text-primary-dark-text-accent dark:hover:text-primary-dark-text-accent"
                                >
                                    activating remote caching
                                </Link>
                                ?
                            </p>
                        </>
                    )}
                </div>
            </div>
            <div className="space-y-4 mt-8">
                <h2 className="font-bold text-2xl">History</h2>
                {data.cache_logs_count === 0 ? (
                    <p>Showing 0 logs</p>
                ) : (
                    <p>
                        Showing {startIndex}-{endIndex} of{' '}
                        {data.cache_logs_count} logs
                    </p>
                )}
                <Pagination
                    style="grayscale"
                    currentPage={data.current_page}
                    totalPages={data.total_pages}
                />
                <div className="mb-8 overflow-x-auto">
                    <div className="relative">
                        <table className="min-w-full text-sm relative">
                            <thead className="bg-white dark:bg-primary-dark-bg sticky border-b dark:border-gray-100/20 top-0">
                                <tr>
                                    <th className="py-2 px-4 text-left w-1/4 font-semibold">
                                        Date
                                    </th>
                                    <th className="py-2 px-4 text-left w-2/4 font-semibold">
                                        Description
                                    </th>
                                    <th className="py-2 px-4 text-center w-1/4 font-semibold">
                                        Number of Cache Entries
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="align-top">
                                {data.cache_logs.map((log, index) => (
                                    <tr
                                        key={index}
                                        className={`border-y dark:border-gray-100/20 dark:text-secondary-dark-text ${
                                            index % 2 === 0
                                                ? 'bg-white dark:bg-gray-800'
                                                : 'bg-gray-50 dark:bg-gray-700'
                                        }`}
                                    >
                                        <td className="py-2 px-4">
                                            {formatDate(
                                                log.created_ts,
                                                'MMMM D, YYYY'
                                            )}
                                        </td>
                                        <td className="py-2 px-4">
                                            {log.description ||
                                                'No description provided'}
                                        </td>
                                        <td className="py-2 px-4 text-center">
                                            {log.cache_entry_count}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    );
}

export default RemoteCache;
